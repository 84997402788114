import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import { withStyles } from "arwes";
import moment from "moment";

import chartTheme from "../util/chartTheme";
import formatNumber from "../util/formatNumber";

const style = (theme) => ({
  root: {
    height: "240px",
    color: "#FF6600",
  },
});

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const BarChart = withStyles(style)(
  ({ classes, keys, data, toggle, legend, format, max }) => (
    <div className={classes.root}>
      <ResponsiveBar
        data={data}
        keys={keys}
        indexBy="updated_at"
        groupMode={toggle ? "stacked" : "grouped"}
        margin={{ top: 30, right: 30, bottom: 44, left: 60 }}
        padding={0.3}
        colors={({ id, data }) => data[`${id}Color`]}
        borderColor={{ from: "color" }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 8,
          tickRotation: 0,
          format: (value) => moment(value).format("MMM DD").toUpperCase(),
        }}
        label={null}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          tickValues: [0, max],
          legend: legend.left,
          legendPosition: "middle",
          legendOffset: -20,
          format: (value) => (format ? formatNumber(value) : value),
        }}
        layers={["grid", "axes", "bars", "markers", "annotations"]}
        enableGridY={false}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        theme={chartTheme}
      />
    </div>
  )
);

export default BarChart;
