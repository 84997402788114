import React from "react";
import { darken, lighten } from "polished";
import {
  Button,
  Col,
  Highlight,
  Line,
  Loading,
  Project,
  Row,
  Table,
  Words,
  withStyles,
} from "arwes";
import { findIndex } from "lodash";

import SocketProvider from "../providers/socket";
import BarChart from "../components/barChart";
import PieChart from "../components/pieChart";
import ShipViewer from "../components/shipViewer";
import formatNumber from "../util/formatNumber";
import StatDisplay from "../components/statDisplay";

const style = (theme) => ({
  project: {
    marginTop: "1rem",
    userSelect: "none",
    height: "555px",
  },
  glow: {
    transition: "250ms filter ease",
    "&:hover": {
      filter: "brightness(120%)",
    },
  },
  reportShip: {
    height: "400px",
    overflow: "hidden",
  },
  reportShipContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  gauge: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  gaugeTitle: {
    margin: "0",
  },
  infoTitle: {
    margin: "0",
  },
});

class Carriers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      carriers: [],
    };
  }

  _processCapacity(carriers) {
    for (let carrier of carriers) {
      const used = carrier.freeSpaceMax - carrier.freeSpaceCurr;

      carrier.capacityStats = [
        { id: 1, label: "Used", color: "#ff6600", value: used },
        {
          id: 2,
          label: "Available",
          color: "#5c2500",
          value: carrier.freeSpaceCurr,
        },
      ];
    }

    return carriers;
  }

  _processFuel(carriers) {
    for (let carrier of carriers) {
      const used = 1000 - carrier.fuelLevel;

      carrier.fuelStats = [
        { id: 1, label: "Used", color: "#5c2500", value: used },
        {
          id: 2,
          label: "Available",
          color: "#ff6600",
          value: carrier.fuelLevel,
        },
      ];
    }

    return carriers;
  }

  _processPresence(carriers) {
    for (let carrier of carriers) {
      const commanders = carrier.squadron.length;
      const diff = 19 - commanders;

      carrier.presenceStats = [
        { id: 1, label: "Onboard", color: "#ff6600", value: commanders },
        { id: 2, label: "Elsewhere", color: "#5c2500", value: diff },
      ];
    }

    return carriers;
  }

  onMessage(records) {
    let record = records.length ? records[0] : records;

    if (record && record.carrierId) {
      // Let's be sure this is a carrier
      let { carriers } = this.state;
      const carrier = record;
      const existingCarrier = findIndex(carriers, { id: carrier.id });

      if (existingCarrier !== -1) {
        carriers[existingCarrier] = carrier;
      } else {
        carriers.push(carrier);
        carriers.sort((a, b) => {
          return b.squadron.length - a.squadron.length;
        });
      }

      carriers = this._processFuel(carriers);
      carriers = this._processCapacity(carriers);
      carriers = this._processPresence(carriers);

      this.setState({
        ...this.state,
        carriers,
      });
    }
  }

  async componentDidMount() {
    let carriers = await SocketProvider.get("/carrier");
    await SocketProvider.get("/subscribe/carriers");

    carriers = this._processFuel(carriers);
    carriers = this._processCapacity(carriers);
    carriers = this._processPresence(carriers);

    carriers.sort((a, b) => {
      return b.squadron.length - a.squadron.length;
    });

    this.setState({
      ...this.state,
      carriers,
    });

    SocketProvider.on("message", this.onMessage.bind(this));
  }

  async componentDidUpdate() {
    const sound = new window.Howl({
      src: "/sound/typing.mp3",
    });

    sound.play();
  }

  componentWillUnmount() {
    SocketProvider.off("message", this.onMessage);
  }

  render() {
    if (!this.state.carriers.length) {
      return <Loading />;
    }

    let carriers = [];

    for (let carrier of this.state.carriers) {
      let squadron = [];

      for (let commander of carrier.squadron) {
        squadron.push(<h1 style={{ margin: 0 }}>{commander.name}</h1>);
      }

      carriers.push(
        <Project
          animate
          header={carrier.name}
          className={`${this.props.classes.project} ${this.props.classes.glow}`}
        >
          <Col s={12} m={2}>
            <Row nested style={{ position: "relative" }}>
              <h1 className={this.props.classes.infoTitle}>
                <span style={{ opacity: 0.5 }}>Owner&nbsp;</span>

                <span>{carrier.commander.name}</span>
              </h1>
              <h1 className={this.props.classes.infoTitle}>
                <span style={{ opacity: 0.5 }}>Type&nbsp;</span>

                <span>
                  {carrier?.meta?.class ? `${carrier.meta.class}-class` : "N/A"}
                </span>
              </h1>
              <h1 className={this.props.classes.infoTitle}>
                <span style={{ opacity: 0.5 }}>Callsign&nbsp;</span>

                <span>{carrier.callsign}</span>
              </h1>
              <h1 className={this.props.classes.infoTitle}>
                <span style={{ opacity: 0.5 }}>Location&nbsp;</span>

                <span style={{ whiteSpace: "nowrap" }}>
                  {carrier.system ? carrier.system : carrier.starsystemName}
                </span>
              </h1>

              <h1 className={this.props.classes.infoTitle}>
                <span style={{ opacity: 0.5 }}>Range (Max)&nbsp;</span>

                <span>{carrier.jumpRangeMax} LY</span>
              </h1>
              <h1 className={this.props.classes.infoTitle}>
                <span style={{ opacity: 0.5 }}>Range (Current)&nbsp;</span>

                <span>{carrier.jumpRangeCurr} LY</span>
              </h1>
              <h1 className={this.props.classes.infoTitle}>
                <span style={{ opacity: 0.5 }}>Access&nbsp;</span>

                <span>{carrier.dockingAccess}</span>
              </h1>
              <h1 className={this.props.classes.infoTitle}>
                <span style={{ opacity: 0.5 }}>Available services</span>
              </h1>
              {carrier?.meta?.hasBlackmarket && (
                <h1 className={this.props.classes.infoTitle}>Black Market</h1>
              )}
              <h1 className={this.props.classes.infoTitle}>Market</h1>
              {carrier?.meta?.hasOutfitting && (
                <h1 className={this.props.classes.infoTitle}>Outfitting</h1>
              )}
              {carrier?.meta?.hasRefuel && (
                <h1 className={this.props.classes.infoTitle}>Refuel</h1>
              )}
              {carrier?.meta?.hasRepair && (
                <h1 className={this.props.classes.infoTitle}>Repair</h1>
              )}
              {carrier?.meta?.hasRearm && (
                <h1 className={this.props.classes.infoTitle}>Rearm</h1>
              )}
              {carrier?.meta?.hasRedemption && (
                <h1 className={this.props.classes.infoTitle}>
                  Redemption Office
                </h1>
              )}
              {carrier?.meta?.hasShipyard && (
                <h1 className={this.props.classes.infoTitle}>Shipyard</h1>
              )}
              {carrier?.meta?.hasUniversal && (
                <h1 className={this.props.classes.infoTitle}>
                  Universal Cartographics
                </h1>
              )}
            </Row>
          </Col>

          <Col s={12} m={6} className={this.props.classes.reportShipContainer}>
            <ShipViewer
              ship="carrier_victory"
              className={this.props.classes.reportShip}
              width={800}
              height={400}
            />
          </Col>

          <Col s={12} m={4}>
            <Row nested>
              <Col s={12} m={4}>
                <section class={this.props.classes.gauge}>
                  <PieChart data={carrier.fuelStats} height={130} />
                  <h1 class={this.props.classes.gaugeTitle}>Fuel</h1>
                </section>
              </Col>

              <Col s={12} m={4}>
                <section class={this.props.classes.gauge}>
                  <PieChart data={carrier.capacityStats} height={130} />
                  <h1 class={this.props.classes.gaugeTitle}>Capacity</h1>
                </section>
              </Col>

              <Col s={12} m={4}>
                <section class={this.props.classes.gauge}>
                  <PieChart data={carrier.presenceStats} height={130} />
                  <h1 class={this.props.classes.gaugeTitle}>Squadron</h1>
                </section>
              </Col>
            </Row>

            <Row nested>
              <Col s={12} m={4}>
                <StatDisplay
                  label="distance travelled"
                  value={formatNumber(
                    parseInt(carrier.stats?.FLEETCARRIER_DISTANCE_TRAVELLED)
                  )}
                />
                <StatDisplay
                  label="credits earned"
                  value={formatNumber(
                    carrier.stats?.FLEETCARRIER_TRADEPROFIT_TOTAL
                  )}
                />
              </Col>

              <Col s={12} m={4}>
                <StatDisplay
                  label="total jumps"
                  value={carrier.stats?.FLEETCARRIER_TOTAL_JUMPS}
                />
                <StatDisplay
                  label="current balance"
                  value={formatNumber(carrier.bankBalance)}
                />
              </Col>

              <Col s={12} m={4}>
                <StatDisplay
                  label="tax rate"
                  value={carrier?.meta?.tax ? `${carrier.meta.tax}%` : "N/A"}
                />
                <StatDisplay
                  label="Last upkeep bill"
                  value={
                    carrier?.meta?.upkeep
                      ? formatNumber(carrier.meta.upkeep)
                      : "N/A"
                  }
                />
              </Col>
            </Row>
          </Col>
        </Project>
      );
    }

    return carriers;
  }
}

export default withStyles(style)(Carriers);
