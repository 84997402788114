import React from "react";
import { ResponsivePie } from "@nivo/pie";
import { withStyles } from "arwes";

import chartTheme from "../util/chartTheme";

const style = (theme) => ({
  root: {
    height: (props) => (props.height ? `${props.height}px` : "230px"),
    width: "100%",
    color: "#FF6600",
  },
});

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const PieChart = withStyles(style)(({ classes, data }) => (
  <div className={classes.root}>
    <ResponsivePie
      data={data}
      margin={{ top: 10, right: 0, bottom: 10, left: 0 }}
      innerRadius={0.75}
      padAngle={0.7}
      colors={({ color }) => color}
      cornerRadius={0}
      borderWidth={1}
      borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
      radialLabelsSkipAngle={10}
      radialLabelsTextXOffset={6}
      radialLabelsTextColor="#333333"
      radialLabelsLinkOffset={0}
      radialLabelsLinkDiagonalLength={16}
      radialLabelsLinkHorizontalLength={24}
      radialLabelsLinkStrokeWidth={1}
      radialLabelsLinkColor={{ from: "color" }}
      slicesLabelsSkipAngle={10}
      slicesLabelsTextColor="#333333"
      enableRadialLabels={false}
      enableSlicesLabels={false}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      theme={chartTheme}
      sortByValue={false}
    />
  </div>
));

export default PieChart;
