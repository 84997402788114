const SocketProvider = {
  SFR: window.io.socket,

  async get(url) {
    return new Promise((resolve) => {
      this.SFR.get(url, (body, { headers, statusCode }) => {
        resolve(body);
      });
    });
  },

  on(event, cb) {
    this.SFR.on(event, cb);
  },

  off(event, cb) {
    this.SFR.off(event, cb);
  },

  disconnect() {
    this.SFR.disconnect();
  },
};

export default SocketProvider;
