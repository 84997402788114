import moment from "moment";

const adjectives = [
  "sharp",
  "haggard",
  "dapper",
  "stern",
  "optimistic",
  "smug",
  "cheery",
  "dashing",
  "successful",
  "guilty",
  "ridiculous",
  "tired",
  "fun",
  "silly",
  "overworked",
  "lazy",
  "sexy",
  "egotistical",
  "political",
  "controversial",
  "evil",
  "presidential",
  "elite",
  "professional",
  "trashy",
  "out of control",
  "in need of some downtime",
  "attractive",
];

const adverbs = [
  "particularly",
  "irrefutably",
  "undeniably",
  "annoyingly",
  "admirably",
  "obviously",
  "certainly",
  "unquestionably",
  "positively",
  "dubiously",
  "questionably",
  "theoretically",
  "thoroughly",
  "staunchly",
  "extremely",
  "brutally",
  "surprisingly",
  "highly",
  "somewhat",
  "slightly",
  "mildly",
  "vividly",
  "joyfully",
  "unbelievably",
  "incredibly",
  "unusually",
];

export default {
  initialGreeting() {
    const adjective = adjectives[Math.floor(Math.random() * adjectives.length)];
    const adverb = adverbs[Math.floor(Math.random() * adverbs.length)];

    return `You're looking ${adverb} ${adjective} this`;
  },

  timeOfDay() {
    const momentObject = moment();

    let g = null;

    const splitAfternoon = 12;
    const splitEvening = 17;
    const currentHour = parseFloat(momentObject.format("HH"));

    if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
      g = "afternoon";
    } else if (currentHour >= splitEvening) {
      g = "evening";
    } else {
      g = "morning";
    }

    return g;
  },
};
