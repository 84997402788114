import { mapKeys } from "lodash";

const dict = {
  adder: "Adder",
  anaconda: "Anaconda",
  asp: "Asp Explorer",
  asp_scout: "Asp Scout",
  belugaliner: "Beluga Liner",
  cobramkiii: "Cobra MK III",
  cobramkiv: "Cobra MK IV",
  cutter: "Imperial Cutter",
  diamondback: "Diamondback Scout",
  diamondbackxl: "Diamondback Explorer",
  dolphin: "Dolphin",
  eagle: "Eagle",
  empire_courier: "Imperial Courier",
  empire_eagle: "Imperial Eagle",
  empire_trader: "Imperial Clipper",
  federation_corvette: "Federal Corvette",
  federation_dropship: "Federal Dropship",
  federation_dropship_mkii: "Federal Assault Ship",
  federation_gunship: "Federal Gunship",
  ferdelance: "Fer-de-Lance",
  hauler: "Hauler",
  independant_trader: "Keelback",
  krait_light: "Krait Phantom",
  krait_mkii: "Krait MK II",
  mamba: "Mamba",
  orca: "Orca",
  python: "Python",
  sidewinder: "Sidewinder MK I",
  type6: "Type-6 Transporter",
  type7: "Type-7 Transporter",
  type9: "Type-9 Heavy",
  type9_military: "Type-10 Defender",
  typex: "Alliance Chieftain",
  typex_2: "Alliance Crusader",
  typex_3: "Alliance Challenger",
  viper: "Viper MK III",
  viper_mkiv: "Viper MK IV",
  vulture: "Vulture",
};

export default {
  identifyShip(id) {
    return dict[id];
  },

  identifyShipName(name) {
    let result;

    mapKeys(dict, (value, key) => {
      if (value === name) {
        result = key;
      }
    });

    return result;
  },
};
