import React from "react";
import { withStyles } from "arwes";

const style = (theme) => ({
  root: {
    padding: ["10px", 0],
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  value: {
    fontSize: "42px",
    fontWeight: "600",
  },
  key: {
    textTransform: "uppercase",
    fontSize: "16px",
    opacity: "0.5",
  },
});

const statDisplay = withStyles(style)(({ classes, label, value }) => (
  <section className={classes.root}>
    <span className={classes.value}>{value}</span>
    <span className={classes.key}>{label}</span>
  </section>
));

export default statDisplay;
