import React from "react";
import { Highlight, Project, Line, Words, withStyles } from "arwes";

const style = (theme) => ({
  root: {},
  project: {
    marginTop: "1rem",
    userSelect: "none",
  },
  words: {
    marginBottom: "1rem",
  },
});

const Home = withStyles(style)(({ classes }) => {
  return (
    <div>
      <Highlight>
        <Project animate header="Uplink Changelog" className={classes.project}>
          <Words
            animate
            animation={{ timeout: 2000 }}
            className={classes.words}
          >
            RELEASE: v3.0.0
          </Words>

          <br></br>

          <ul>
            <li>Rumours of Uplink's demise have been greatly exaggerated.</li>
          </ul>

          <br></br>

          <Line animate />

          <Words
            animate
            animation={{ timeout: 2000 }}
            className={classes.words}
          >
            RELEASE: v2.9.1
          </Words>

          <br></br>

          <ul>
            <li>Fixed some bugs related to realtime leaderboard math.</li>
          </ul>

          <br></br>

          <Line animate />

          <Words
            animate
            animation={{ timeout: 2000 }}
            className={classes.words}
          >
            RELEASE: v2.9.0
          </Words>

          <br></br>

          <ul>
            <li>Dashboard update beta. There will be bugs.</li>
          </ul>

          <br></br>

          <Line animate />

          <Words
            animate
            animation={{ timeout: 2000 }}
            className={classes.words}
          >
            RELEASE: v2.8.0
          </Words>

          <br></br>

          <ul>
            <li>New panel: Dashboard, which shows the day's objectives.</li>
          </ul>

          <br></br>

          <Line animate />

          <Words
            animate
            animation={{ timeout: 2000 }}
            className={classes.words}
          >
            RELEASE: v2.7.2
          </Words>

          <br></br>

          <ul>
            <li>
              Trades interface now shows trades since last tick (previously it
              was last 24 hours.)
            </li>
          </ul>

          <br></br>

          <Line animate />

          <Words
            animate
            animation={{ timeout: 2000 }}
            className={classes.words}
          >
            RELEASE: v2.7.0
          </Words>

          <br></br>

          <ul>
            <li>Trade bombing interface enters early testing phase.</li>
          </ul>

          <br></br>

          <Line animate />

          <Words
            animate
            animation={{ timeout: 2000 }}
            className={classes.words}
          >
            RELEASE: v2.6.0
          </Words>

          <br></br>

          <ul>
            <li>
              Discord authorization is fully implemented. And required. Which
              you already know if you're reading this.
            </li>
            <li>Sessions now last 30 days.</li>
            <li>
              You'll start to see some changes in the UI over the coming weeks
              now that Uplink is contextual to the logged-in CMDR.
            </li>
          </ul>

          <br></br>

          <Line animate />

          <Words
            animate
            animation={{ timeout: 2000 }}
            className={classes.words}
          >
            RELEASE: v2.5.1
          </Words>

          <br></br>

          <ul>
            <li>
              Real-time functionality: on the Missions interface, updated
              missions will now jump to the top of the board. This is what they
              do on initial load anyway, making it more consistent (and easier
              to see that mission you just failed.)
            </li>
          </ul>

          <br></br>

          <Line animate />

          <Words
            animate
            animation={{ timeout: 2000 }}
            className={classes.words}
          >
            RELEASE: v2.5.0
          </Words>

          <br></br>

          <ul>
            <li>Missions Logs is now Missions. Added stats.</li>
            <li>Removed Influence until it can be reworked.</li>
            <li>Removed some SRUN references.</li>
          </ul>

          <br></br>

          <Line animate />

          <Words
            animate
            animation={{ timeout: 2000 }}
            className={classes.words}
          >
            RELEASE: v2.4.2
          </Words>

          <br></br>

          <ul>
            <li>Fixed an issue with date formatting on the killboard.</li>
          </ul>

          <br></br>

          <Line animate />

          <Words
            animate
            animation={{ timeout: 2000 }}
            className={classes.words}
          >
            RELEASE: v2.4.1
          </Words>

          <br></br>

          <ul>
            <li>
              Fixed an issue with carrier "distanced travelled" stat format.
            </li>
          </ul>

          <br></br>

          <Line animate />

          <Words
            animate
            animation={{ timeout: 2000 }}
            className={classes.words}
          >
            RELEASE: v2.4.0
          </Words>

          <br></br>

          <ul>
            <li>
              Added an AX interface, with a leaderboard detailing the squadron's
              efforts against the Thargoids.
            </li>
          </ul>

          <br></br>

          <Line animate />

          <Words
            animate
            animation={{ timeout: 2000 }}
            className={classes.words}
          >
            RELEASE: v2.3.0
          </Words>

          <br></br>

          <ul>
            <li>
              Overhaul of the Conflicts interface. New wars will now be much
              easier to add, as they come from a new CMS we implemented.
            </li>
            <li>Wars should now support real-time properly.</li>
          </ul>

          <br></br>

          <Line animate />

          <Words
            animate
            animation={{ timeout: 2000 }}
            className={classes.words}
          >
            RELEASE: v2.2.1
          </Words>

          <br></br>

          <ul>
            <li>Minor visual bugfix release.</li>
          </ul>

          <br></br>

          <Line animate />

          <Words
            animate
            animation={{ timeout: 2000 }}
            className={classes.words}
          >
            RELEASE: v2.2.0
          </Words>

          <br></br>

          <ul>
            <li>
              Carriers: Enabled real-time data updates. Still working out some
              kinks with these new API events from FDEV.
            </li>
            <li>
              Influence: Now defaults the leaderboard to 7-day rolling stats,
              with 30-day and all-time stats available.
            </li>
          </ul>

          <br></br>

          <Line animate />

          <Words
            animate
            animation={{ timeout: 2000 }}
            className={classes.words}
          >
            RELEASE: v2.1.0
          </Words>

          <br></br>

          <ul>
            <li>New interface available: Carriers.</li>
          </ul>

          <br></br>

          <Line animate />

          <Words
            animate
            animation={{ timeout: 2000 }}
            className={classes.words}
          >
            RELEASE: v2.0.2
          </Words>

          <br></br>

          <ul>
            <li>
              Killboard: Fixed an issue where Pythons were mysteriously missing
              from the victims display.
            </li>
            <li>Killboard: Made Chad not sad.</li>
          </ul>

          <br></br>

          <Line animate />

          <Words
            animate
            animation={{ timeout: 2000 }}
            className={classes.words}
          >
            RELEASE: v2.0.1
          </Words>

          <br></br>

          <ul>
            <li>Killboard: Sharper ship kill vs. loss differentiation.</li>
            <li>Killboard: Added the ability to filter kills by commander.</li>
          </ul>

          <br></br>

          <Line animate />

          <Words
            animate
            animation={{ timeout: 2000 }}
            className={classes.words}
          >
            RELEASE: v2.0.0
          </Words>

          <br></br>

          <ul>
            <li>
              Killboard: Now uses realtime data, completing the effort to make
              all aspects of Uplink update automatically.
            </li>
            <li>
              Killboard: Fixed an issue with overly optimistic squadron
              efficiency on the associated graph.
            </li>
            <li>
              SFR: Now also supports realtime.{" "}
              <a
                href="https://github.com/dougestey/sfr/releases/tag/v1.2.0"
                target="_blank"
              >
                Download v1.2.0
              </a>{" "}
              to receive this upgrade.
            </li>
            <li>
              There will almost certainly be bugs in this release, specifically
              regarding combat/interdictions. Please report anything odd you
              see.
            </li>
          </ul>

          <br></br>

          <Line animate />

          <Words
            animate
            animation={{ timeout: 2000 }}
            className={classes.words}
          >
            RELEASE: v1.8.0
          </Words>

          <br></br>

          <ul>
            <li>
              All interfaces, with the exception of the killboard, are now
              updated in realtime the moment they're uploaded from the
              Straylight Flight Recorder.
            </li>
          </ul>

          <br></br>

          <Line animate />

          <Words
            animate
            animation={{ timeout: 2000 }}
            className={classes.words}
          >
            RELEASE: v1.7.0
          </Words>

          <br></br>

          <ul>
            <li>
              Influence: Added mission statistics for the current day;
              reorganized the influence UI.
            </li>
            <li>
              Killboard: Fixed a bug where being killed by a wing of other
              commanders would break the killboard.
            </li>
            <li>Killboard: Cleaned up leader display.</li>
            <li>Killboard: Losses to NPCs are no longer displayed.</li>
          </ul>

          <br></br>

          <Line animate />

          <Words
            animate
            animation={{ timeout: 2000 }}
            className={classes.words}
          >
            RELEASE: v1.6.0
          </Words>

          <br></br>

          <ul>
            <li>
              Killboard: Added detailed kill reports based on scan data. 3D
              models are available for all 38 ship classes.
            </li>
            <li>
              Killboard: Reworked statistics. All commanders involved are given
              kill credit. Assists are detailed.
            </li>
            <li>Killboard: Added victim ship class graph display.</li>
            <li>
              General: Cleaned up timestamps, and they now reflect the current
              year (3306, if you forgot.)
            </li>
            <li>General: Added hover markers to all tables.</li>
          </ul>

          <br></br>

          <Line animate />

          <Words
            animate
            animation={{ timeout: 2000 }}
            className={classes.words}
          >
            RELEASE: v1.5.0
          </Words>

          <br></br>

          <ul>
            <li>New interface available: Conflicts.</li>
          </ul>

          <br></br>

          <Line animate />

          <Words
            animate
            animation={{ timeout: 2000 }}
            className={classes.words}
          >
            RELEASE: v1.4.2
          </Words>

          <br></br>

          <ul>
            <li>Minor bugfix release.</li>
          </ul>

          <br></br>

          <Line animate />

          <Words
            animate
            animation={{ timeout: 2000 }}
            className={classes.words}
          >
            RELEASE: v1.4.1
          </Words>

          <br></br>

          <ul>
            <li>Fixed a display issue on the influence system graph.</li>
            <li>
              Fixed an issue across all data tables where, occassionally, row
              items would not show up in the right order.
            </li>
          </ul>

          <br></br>

          <Line animate />

          <Words
            animate
            animation={{ timeout: 2000 }}
            className={classes.words}
          >
            RELEASE: v1.4.0
          </Words>

          <br></br>

          <ul>
            <li>
              Immersion: added sound to the Straylight Uplink interface.
              Everywhere. There's no escape. Except for the mute button.
            </li>
            <li>
              Updated the Influence screen to use 7 days of data for both
              graphs.
            </li>
            <li>Changed the default view for the INF / MISSIONS graph.</li>
            <li>Fixed some rendering issues for the INF / SYSTEM graph.</li>
            <li>Fixed some static assets that were causing misalignment.</li>
          </ul>

          <br></br>

          <Line animate />

          <Words
            animate
            animation={{ timeout: 2000 }}
            className={classes.words}
          >
            RELEASE: v1.3.0
          </Words>

          <br></br>

          <ul>
            <li>Leaderboard is now Influence.</li>
            <li>
              Added visual representation of mission INF contributons in the
              form of graphs that were fucking hard to make.
            </li>
            <li>
              Added a graph showing the last 6 days of influence changes in
              Moram.
            </li>
            <li>
              Added total # of missions completed for the period to the
              leaderboard.
            </li>
          </ul>

          <br></br>

          <Line animate />

          <Words
            animate
            animation={{ timeout: 2000 }}
            className={classes.words}
          >
            RELEASE: v1.2.0
          </Words>

          <br></br>

          <ul>
            <li>
              Reworked killboard stats to actually reflect the way math works.
            </li>
            <li>Added top interdictions to the killboard.</li>
            <li>Added aggression indicators to combat logs.</li>
            <li>
              Fixed an issue where the Straylight faction INF would magically
              appear in a different order, causing things to break.
            </li>
          </ul>

          <br></br>

          <Line animate />

          <Words
            animate
            animation={{ timeout: 2000 }}
            className={classes.words}
          >
            RELEASE: v1.1.1
          </Words>

          <br></br>

          <ul>
            <li>
              Fixed an issue where getting killed by a station would break the
              killboard.
            </li>
          </ul>

          <br></br>

          <Line animate />

          <Words
            animate
            animation={{ timeout: 2000 }}
            className={classes.words}
          >
            RELEASE: v1.1.0
          </Words>

          <br></br>

          <ul>
            <li>New report: Killboard</li>
            <li>Updates to mobile layout.</li>
            <li>Removed NPC interdictions from combat logs.</li>
            <li>Added rankings to leaderboard... yeah.</li>
          </ul>

          <br></br>

          <Line animate />

          <Words
            animate
            animation={{ timeout: 2000 }}
            className={classes.words}
          >
            RELEASE: v1.0.0
          </Words>

          <br></br>

          <ul>
            <li>Initial release.</li>
          </ul>

          <br></br>
        </Project>
      </Highlight>
    </div>
  );
});

export default Home;
