import React from "react";
import { Highlight, Loading, Project, Table, withStyles } from "arwes";
import moment from "moment";
import { findIndex } from "lodash";

import SocketProvider from "../providers/socket";

const style = (theme) => ({
  row: {
    "&:hover": {
      background: "#FF6600",
      color: "#000000",
    },
  },
  project: {
    marginTop: "1rem",
    userSelect: "none",
  },
  words: {
    marginBottom: "1rem",
  },
});

class CombatLog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
    };
  }

  onMessage(records) {
    let record = records.length ? records[0] : records;

    if (record && (record.state || record.ship)) {
      // Is an interdict or kill
      const { data } = this.state;
      const existingRecord = findIndex(data, "id", record.id);

      if (existingRecord !== -1) {
        data[existingRecord] = record;
      } else {
        data.push(record);
        data.sort((a, b) => {
          return (
            new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
          );
        });
      }

      this.setState({
        ...this.state,
        data,
      });
    }
  }

  async componentDidMount() {
    const interdictions = await SocketProvider.get(
      "/interdiction?sort=createdAt DESC&limit=2000"
    );
    const kills = await SocketProvider.get(
      "/kill?sort=createdAt DESC&limit=2000"
    );

    await SocketProvider.get("/subscribe/kills");
    await SocketProvider.get("/subscribe/interdictions");

    const data = [...interdictions, ...kills];

    data.sort((a, b) => {
      return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
    });

    this.setState({
      ...this.state,
      data: [...this.state.data, ...data],
    });

    SocketProvider.on("message", this.onMessage.bind(this));
  }

  async componentDidUpdate() {
    const sound = new window.Howl({
      src: "/sound/typing.mp3",
    });

    sound.play();
  }

  componentWillUnmount() {
    SocketProvider.off("message", this.onMessage);
  }

  render() {
    if (!this.state.data.length) {
      return <Loading />;
    }

    const rows = [];

    this.state.data.map((row) => {
      let commander, type, aggro;

      if (row.commander) {
        commander = row.commander;
        type = "interdict";
      } else {
        commander = row.attacker || row.victim;
        type = "kill";
      }

      if (type === "interdict" && !row.data.isPlayer) {
        return;
      }

      if (type === "kill") {
        aggro = row.attacker ? ">>>" : "<<<";
      } else {
        // interdict
        aggro = row.state === "offense" ? ">>>" : "<<<";
      }

      rows.push(
        <tr className={this.props.classes.row}>
          <td>
            {moment(row.updatedAt).add(1286, "years").format("YYYY")}-
            {moment(row.updatedAt).utc().format("MM-DD HH:mm:ss")}
          </td>
          <td>{type.toUpperCase()}</td>
          <td>{commander.name.toUpperCase()}</td>
          <td>{aggro}</td>
          <td>
            {row.data.opponentName
              ? row.data.opponentName.toUpperCase()
              : "STATION"}
          </td>
          <td>{row.data.starsystemName}</td>
        </tr>
      );
    });

    return (
      <Highlight>
        <Project
          animate
          header="Combat Logs"
          className={this.props.classes.project}
        >
          <Table animate>
            <table>
              <thead>
                <tr>
                  <th>TIMESTAMP</th>
                  <th>TYPE</th>
                  <th>CMDR</th>
                  <th>AGGRO</th>
                  <th>OPPONENT</th>
                  <th>SYSTEM</th>
                </tr>
              </thead>

              <tbody>{rows}</tbody>
            </table>
          </Table>
        </Project>
      </Highlight>
    );
  }
}

export default withStyles(style)(CombatLog);
