import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Redirect,
} from "react-router-dom";
import {
  Arwes,
  Button,
  Col,
  createSounds,
  createTheme,
  Loading,
  SoundsProvider,
  ThemeProvider,
} from "arwes";

import eliteTheme from "./util/theme";
import Sidebar from "./components/sidebar";
import CombatLog from "./pages/CombatLog";
import MissionLog from "./pages/MissionLog";
import About from "./pages/About";
import Dashboard from "./pages/Dashboard";
import Leaderboard from "./pages/Leaderboard";
import Killboard from "./pages/Killboard";
import Updates from "./pages/Updates";
import Conflicts from "./pages/Conflicts";
import Carriers from "./pages/Carriers";
import AX from "./pages/AX";
import Campaigns from "./pages/Campaigns";
import SocketProvider from "./providers/socket";
import SessionProvider from "./providers/session";

import "./App.css";

const defaultSounds = {
  shared: { volume: 1 }, // Shared sound settings
  players: {
    // The player settings
    click: {
      // With the name the player is created
      sound: { src: ["/sound/click.mp3"] }, // The settings to pass to Howler
    },
    typing: {
      sound: { src: ["/sound/typing.mp3"] },
      settings: { oneAtATime: true }, // The custom app settings
    },
    deploy: {
      sound: { src: ["/sound/deploy.mp3"] },
      settings: { oneAtATime: true },
    },
    ask: {
      // With the name the player is created
      sound: { src: ["/sound/ask.mp3"] }, // The settings to pass to Howler
    },
    error: {
      // With the name the player is created
      sound: { src: ["/sound/error.mp3"] }, // The settings to pass to Howler
    },
    information: {
      // With the name the player is created
      sound: { src: ["/sound/information.mp3"] }, // The settings to pass to Howler
    },
    warning: {
      // With the name the player is created
      sound: { src: ["/sound/warning.mp3"] }, // The settings to pass to Howler
    },
  },
};

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthed: false,
    };
  }

  async componentDidMount() {
    setTimeout(async () => {
      const data = await SocketProvider.get("/auth/whoami");
      const isProduction = process.env.NODE_ENV === "production";

      if (!data || !data.id) {
        return (window.location = isProduction
          ? "https://sfr.straylight.systems/auth/discord"
          : "http://localhost:1337/auth/discord");
      }

      SessionProvider.set(data);

      this.setState({
        ...this.state,
        isAuthed: true,
      });
    }, 1000);
  }

  render() {
    if (!this.state.isAuthed) {
      return (
        <ThemeProvider theme={createTheme(eliteTheme)}>
          <SoundsProvider sounds={createSounds(defaultSounds)}>
            <Arwes animate>
              <Loading />
            </Arwes>
          </SoundsProvider>
        </ThemeProvider>
      );
    }

    return (
      <ThemeProvider theme={createTheme(eliteTheme)}>
        <SoundsProvider sounds={createSounds(defaultSounds)}>
          <Arwes animate>
            <Router>
              <Col s={12}>
                <Sidebar>
                  <NavLink to="/" exact={true}>
                    <Button animate>Dashboard</Button>
                  </NavLink>

                  <NavLink to="/killboard">
                    <Button animate>Killboard</Button>
                  </NavLink>

                  <NavLink to="/carriers">
                    <Button animate>Carriers</Button>
                  </NavLink>

                  <NavLink to="/conflicts">
                    <Button animate>Conflicts</Button>
                  </NavLink>

                  {/* <NavLink to="/ax">
                    <Button animate>AX</Button>
                  </NavLink> */}

                  <NavLink to="/missions">
                    <Button animate>Missions</Button>
                  </NavLink>

                  <NavLink to="/trades">
                    <Button animate>Trades</Button>
                  </NavLink>

                  <NavLink to="/combat">
                    <Button animate>Log</Button>
                  </NavLink>

                  <NavLink to="/updates">
                    <Button animate>Updates</Button>
                  </NavLink>
                </Sidebar>
              </Col>
              {/*
              A <Switch> looks through all its children <Route>
              elements and renders the first one whose path
              matches the current URL. Use a <Switch> any time
              you have multiple routes, but you want only one
              of them to render at a time
            */}
              <Col s={12}>
                <Switch>
                  <Route exact path="/">
                    <Dashboard />
                  </Route>
                  <Route exact path="/combat">
                    <CombatLog />
                  </Route>
                  <Route exact path="/trades">
                    <Campaigns />
                  </Route>
                  <Route exact path="/missions">
                    <MissionLog />
                  </Route>
                  <Route exact path="/ax">
                    <AX />
                  </Route>
                  <Route exact path="/conflicts">
                    <Conflicts />
                  </Route>
                  <Route exact path="/carriers">
                    <Carriers />
                  </Route>
                  <Route exact path="/killboard">
                    <Killboard />
                  </Route>
                  <Route exact path="/updates">
                    <Updates />
                  </Route>
                  {/* <Route path="/" exact>
                    <Redirect to="/" />
                  </Route> */}
                </Switch>
              </Col>
            </Router>
          </Arwes>
        </SoundsProvider>
      </ThemeProvider>
    );
  }
}

export default App;
