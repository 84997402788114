import React from "react";
import { Frame, withStyles } from "arwes";
import { ReactComponent as Logo } from "../assets/empire.svg";

const style = (theme) => ({
  root: {
    padding: ["10px", 0],
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    letterSpacing: "1.3px",
    textAlign: "center",
    textTransform: "uppercase",
    margin: "0 1.5rem 0 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  section: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    fontFamily: "Eurostile Regular",
    padding: "0 0.3rem",
  },
  logo: {
    marginRight: "1.2rem",
    marginLeft: "0.8rem",
    width: "30px",
    height: "30px",
    transform: "translate(5px, 3px)",
  },
  version: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    flexGrow: "1",
    paddingRight: "1rem",
  },
});

const sidebar = withStyles(style)(({ classes, children }) => (
  <Frame animate level={0} corners={2} className={classes.root}>
    <section className={classes.section}>
      <Logo className={classes.logo} />

      <h1 className={classes.title}>Straylight</h1>

      {children}

      <div className={classes.version}>
        <small>3.0.0</small>
      </div>
    </section>
  </Frame>
));

export default sidebar;
