import React from "react";
import STLViewer from "stl-viewer";

const cameraMap = {
  adder: {
    cameraY: -50,
    cameraZ: 10,
  },
  anaconda: {
    cameraY: -200,
    cameraZ: 10,
  },
  asp: {
    cameraY: -100,
    cameraZ: 10,
  },
  asp_scout: {
    cameraY: -100,
    cameraZ: 10,
  },
  belugaliner: {
    // currently using orca model
    cameraY: -125,
    cameraZ: 10,
  },
  carrier_fortune: {
    cameraY: -30,
    cameraZ: 8,
  },
  carrier_victory: {
    cameraY: -30,
    cameraZ: 8,
  },
  cobramkiii: {
    cameraY: -75,
    cameraZ: 10,
  },
  cobramkiv: {
    cameraY: -75,
    cameraZ: 10,
  },
  cutter: {
    cameraY: -260,
    cameraZ: 35,
  },
  diamondback: {
    cameraY: -60,
    cameraZ: 10,
  },
  diamondbackxl: {
    cameraY: -60,
    cameraZ: 10,
  },
  dolphin: {
    cameraY: -75,
    cameraZ: 20,
  },
  eagle: {
    cameraY: -50,
    cameraZ: 10,
  },
  empire_courier: {
    cameraY: -56,
    cameraZ: 10,
  },
  empire_eagle: {
    cameraY: -50,
    cameraZ: 10,
  },
  empire_trader: {
    cameraY: -185,
    cameraZ: 10,
  },
  federation_corvette: {
    cameraY: -235,
    cameraZ: 35,
  },
  federation_dropship: {
    cameraY: -100,
    cameraZ: 20,
  },
  federation_dropship_mkii: {
    cameraY: -100,
    cameraZ: 20,
  },
  federation_gunship: {
    cameraY: -100,
    cameraZ: 20,
  },
  ferdelance: {
    cameraY: -250,
    cameraZ: 50,
  },
  hauler: {
    cameraY: -50,
    cameraZ: 10,
  },
  independant_trader: {
    cameraY: -75,
    cameraZ: 20,
  },
  krait_mkii: {
    cameraY: -500,
    cameraZ: 50,
  },
  krait_light: {
    cameraY: -100,
    cameraZ: 35,
  },
  mamba: {
    cameraY: -120,
    cameraZ: 20,
  },
  orca: {
    cameraY: -175,
    cameraZ: 10,
  },
  python: {
    cameraY: -125,
    cameraZ: 10,
  },
  sidewinder: {
    cameraY: -35,
    cameraZ: 10,
  },
  type6: {
    cameraY: -75,
    cameraZ: 10,
  },
  type7: {
    cameraY: -125,
    cameraZ: 10,
  },
  type9: {
    cameraY: -175,
    cameraZ: 10,
  },
  type9_military: {
    // type-10
    cameraY: -200,
    cameraZ: 20,
  },
  typex: {
    // chieftain
    cameraY: -110,
    cameraZ: 30,
  },
  typex_2: {
    // crusader
    cameraY: -110,
    cameraZ: 30,
  },
  typex_3: {
    // challenger, currently using crusader model
    cameraY: -110,
    cameraZ: 30,
  },
  viper: {
    cameraY: -50,
    cameraZ: 10,
  },
  viper_mkiv: {
    cameraY: -50,
    cameraZ: 10,
  },
  vulture: {
    cameraY: -75,
    cameraZ: 10,
  },
};

const shipViewer = ({ ship, isLoss, width, height }) => {
  const map = cameraMap[ship];

  if (!map) {
    return null;
  }

  return (
    <STLViewer
      url={`/models/${ship}.stl`}
      cameraX={0}
      cameraY={map.cameraY}
      cameraZ={map.cameraZ}
      width={width ? width : 300}
      height={height ? height : 200}
      lights={[0, 0, 1]}
      modelColor={isLoss ? "red" : "#FF6600"}
      backgroundColor="#000000"
      rotate={true}
      orbitControls={true}
      rotationSpeeds={[0, 0, 0.003]}
    />
  );
};

export default shipViewer;
