import React, { useEffect, useState } from "react";
import {
  Appear,
  Button,
  Col,
  Highlight,
  Project,
  Line,
  List,
  Row,
  Words,
  withStyles,
} from "arwes";
import SessionProvider from "../providers/session";

const style = (theme) => ({
  root: {},
  project: {
    marginTop: "1rem",
    userSelect: "none",
  },
  words: {
    marginBottom: "1rem",
  },
});

const Home = withStyles(style)(({ classes }) => {
  const [showA, setShowA] = useState(false);
  const [showB, setShowB] = useState(false);
  const [showC, setShowC] = useState(false);
  const session = SessionProvider.get();
  const { profile } = session;

  useEffect(() => {
    setTimeout(() => {
      setShowA(true);
    }, 1000);

    setTimeout(() => {
      setShowB(true);
    }, 1500);

    setTimeout(() => {
      setShowC(true);
    }, 2000);
  });

  return (
    <div>
      <Row nested>
        <Col m={12} l={8}>
          <Highlight>
            <Project
              animate
              header={`Welcome to Straylight, Commander ${profile.username}.`}
              className={classes.project}
            >
              <Words animate className={classes.words}>
                Uplink to Straylight Data Network... established.
              </Words>

              <Line animate />

              <Words animate show={showA} className={classes.words}>
                This interface can be used monitor information about ongoing
                squadron operations. Your clearance level has been verified, and
                this connection is secure.
              </Words>

              <br></br>

              <Words animate show={showA} className={classes.words}>
                Data is provided by the Straylight Flight Recorder. Without it,
                you will not appear on Uplink.&nbsp;&nbsp;&nbsp;
              </Words>

              <Appear animate={true} show={showB}>
                <a href="https://github.com/chadvangaalen/sfr" target="_blank">
                  <Button animate>Download SFR</Button>
                </a>
              </Appear>

              <Line animate />

              <Words animate show={showB} className={classes.words}>
                Straylight is a distributed collective of war veterans,
                smugglers, and other social misfits operating as mercenaries for
                hire. Pilots engage in a myriad of specialties, and are usually
                open to a broad range of contracts - especially when special
                operations and tactics are hard requirements.
              </Words>

              <Words animate show={showC} className={classes.words}>
                Not outwardly hostile to the general public, Straylight pilots
                are still formidable opponents to those foolish enough to engage
                them in combat. Reports have indicated that they spar frequently
                with the Federation ever since it left an Imperial station full
                of innocent souls to burn at the hands of the Thargoids.
              </Words>
            </Project>
          </Highlight>
        </Col>

        <Col m={12} l={4}>
          <Highlight>
            <Project animate header="Resources" className={classes.project}>
              <Words animate className={classes.words}>
                Take advantage of the resources we've made available to you,
                pilot. Straylight will be stronger for it.
              </Words>

              <Row>
                <Col s={12}>
                  <List node="ul">
                    <li>
                      <a
                        href="https://github.com/chadvangaalen/sfr"
                        target="_blank"
                      >
                        Download SFR
                      </a>
                    </li>

                    <li>
                      <a
                        href="https://helix.straylight.systems"
                        target="_blank"
                      >
                        Access Helix
                      </a>
                    </li>

                    <li>
                      <a
                        href="https://inara.cz/squadron/10684/"
                        target="_blank"
                      >
                        Register on Inara
                      </a>
                    </li>
                  </List>
                </Col>
              </Row>
            </Project>
          </Highlight>
        </Col>
      </Row>
    </div>
  );
});

export default Home;
