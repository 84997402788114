import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import { withStyles } from "arwes";
import moment from "moment";

import chartTheme from "../util/chartTheme";
import formatNumber from "../util/formatNumber";

const style = (theme) => ({
  root: {
    height: "360px",
    color: "#FF6600",
  },
});

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const BarChart = withStyles(style)(
  ({ classes, keys, data, toggle, legend, format }) => (
    <div className={classes.root}>
      <ResponsiveBar
        data={data}
        keys={keys}
        indexBy="updated_at"
        groupMode={toggle ? "stacked" : "grouped"}
        margin={{ top: 30, right: 30, bottom: 30, left: 60 }}
        padding={0.3}
        colors={({ id, data }) => data[`${id}Color`]}
        borderColor={{ from: "color" }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 15,
          tickRotation: 0,
          format: (value) => moment(value).format("MMM DD").toUpperCase(),
        }}
        label={null}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend,
          legendPosition: "middle",
          legendOffset: -40,
          format: (value) => (format ? formatNumber(value) : value),
        }}
        layers={["grid", "axes", "bars", "markers", "annotations"]}
        enableGridY={false}
        legends={[
          {
            dataFrom: "keys",
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        theme={chartTheme}
      />
    </div>
  )
);

export default BarChart;
