import React from "react";
import { darken, lighten } from "polished";
import moment from "moment";
import { sumBy } from "lodash";
import {
  Button,
  Col,
  Highlight,
  Line,
  Loading,
  Project,
  Row,
  Table,
  Words,
  withStyles,
} from "arwes";

import SocketProvider from "../providers/socket";
import BarChart from "../components/barChart";
import PieChart from "../components/pieChart";
import StatDisplay from "../components/statDisplay";
import formatNumber from "../util/formatNumber";

const style = (theme) => ({
  row: {
    "&:hover": {
      background: "#FF6600",
      color: "#000000",
    },
  },
  project: {
    marginTop: "1rem",
    userSelect: "none",
  },
  expiredProject: {
    marginTop: "1rem",
    userSelect: "none",
    opacity: "0.5",
    transition: "175ms opacity ease-in",
    "&:hover": {
      opacity: "1",
    },
  },
  words: {
    marginBottom: "1rem",
    fontSize: "1.1rem",
  },
  toggle: {
    position: "absolute",
    top: "19.5px",
    right: "19.5px",
  },
  barGraph: {
    marginBottom: "2rem",
  },
  glow: {
    transition: "250ms filter ease",
    "&:hover": {
      filter: "brightness(120%)",
    },
  },
  select: {
    backgroundColor: "black",
    color: "#FF6600",
    borderColor: "rgba(153,61,0,0.65)",
    textTransform: "uppercase",
    padding: "12px 18px",
    mozAppearance: "none",
    webkitAppearance: "none",
    appearance: "none",
    position: "absolute",
    top: "15px",
    right: "12px",
    fontFamily: "Eurostile Regular",
    fontSize: "18px",
    fontWeight: "600",
    outline: "none",
    transition: "175ms borderColor ease",
    "&:hover": {
      borderColor: "#FF6600",
    },
  },
  option: {
    "&:checked)": {
      backgroundColor: "#FF6600",
    },
  },
  stats: {
    display: "flex",
    justifyContent: "space-around",
  },
});

let pilotCount = 0;

const _resetColor = () => {
  pilotCount = 0;
};

const _colorFn = () => {
  const value = pilotCount;
  const color = darken(value, "#FF6600");

  pilotCount = pilotCount + 0.05;

  return color;
};

class Conflicts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      wars: [],
      barToggle: true,
    };
  }

  _addBond(bond, time, war) {
    for (let day of war.days) {
      if (day.updated_at === time) {
        day.data.push(bond);
      }
    }
  }

  _createDay(bond, time) {
    return {
      updated_at: time,
      data: [bond],
    };
  }

  _updateRanks(bond, ranks) {
    // Stats
    let commander = ranks.find(
      (commander) => commander.name === bond.commander.name
    );
    let amount = bond.data.amount || bond.data.reward;

    if (commander) {
      commander.meta.score = commander.meta.score + amount;
      commander.meta.total++;
    } else {
      commander = {
        ...bond.commander,
        meta: {
          score: amount ? amount : 0,
          total: 1,
        },
      };

      ranks.push(commander);
    }

    return ranks;
  }

  onMessage(bond) {
    let { wars, ranks } = this.state;

    for (let war of wars) {
      const isRelevant =
        bond.data.faction === war.faction ||
        bond.data.awardingFaction === war.faction;

      if (
        isRelevant &&
        (!bond.data.type || (bond.data.type && bond.data.type !== "bounty"))
      ) {
        const warRanks = war.ranks;
        const { timestamp } = bond;
        const time = moment(timestamp).utc().startOf("day").toDate().getTime();
        let day = war.days.find((d) => d.updated_at === time);

        if (!day) {
          day = this._createDay(bond, time);

          war.days.push(day);
        } else {
          this._addBond(bond, time, war);
        }

        // Stats
        let commander = warRanks.find(
          (commander) => commander.name === bond.commander.name
        );
        let amount = bond.data.amount || bond.data.reward;

        if (commander) {
          commander.meta.score = commander.meta.score + amount;
          commander.meta.total++;
        } else {
          commander = {
            ...bond.commander,
            meta: {
              score: amount ? amount : 0,
              total: 1,
            },
          };

          warRanks.push(commander);
        }

        ranks = this._updateRanks(bond, ranks);

        ranks.sort((a, b) => {
          return b.meta.score - a.meta.score;
        });

        this.setState({
          ...this.state,
          wars,
          ranks,
        });
      }
    }
  }

  async onSetWarTable(event) {
    event.persist();

    let {
      target: { value },
    } = event;
    let { wars, ranks, selectedRanks } = this.state;

    if (value === "all") {
      selectedRanks = ranks;

      return this.setState({
        ...this.state,
        selectedRanks,
      });
    }

    value = parseInt(value);

    selectedRanks = wars[value].ranks;

    selectedRanks.sort((a, b) => {
      return b.meta.score - a.meta.score;
    });

    this.setState({
      ...this.state,
      selectedRanks,
    });
  }

  async componentDidMount() {
    const { wars, ranks } = await SocketProvider.get("/wars");
    await SocketProvider.get("/subscribe/bonds");

    ranks.sort((a, b) => {
      return b.meta.score - a.meta.score;
    });

    wars.sort((a, b) => {
      return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
    });

    // Animate the delta from 0 to current values
    const warsInitial = [];

    if (wars.length && ranks.length) {
      for (let i = 0; i < wars.length; i++) {
        const war = wars[i];
        const graphKeys = [];
        let participated = 0;

        warsInitial.push({
          days: [],
          participation: [
            { id: 1, label: "Active CMDRs", color: "#ff6600", value: 0 },
            { id: 2, label: "AWOL CMDRs", color: "#5c2500", value: 0 },
          ],
        });

        for (let commander of war.ranks) {
          // Build graph keys
          if (!graphKeys.find((name) => name === commander.name)) {
            graphKeys.push(commander.name);
          }

          // Assign CMDR colors
          commander.color = _colorFn(commander.name);

          // Bump participation
          participated++;
        }

        war.keys = graphKeys;
        war.participation = [
          {
            id: 1,
            label: "Active CMDRs",
            color: "#ff6600",
            value: participated,
          },
          {
            id: 2,
            label: "AWOL CMDRs",
            color: "#5c2500",
            value: 19 - participated,
          },
        ];

        _resetColor();

        war.days.map((day) => {
          const stats = {};

          warsInitial[i].days.push({
            updated_at: day.updated_at,
          });

          day.data.map((bond) => {
            const { name } = bond.commander;
            const amount = bond.data.amount || bond.data.reward;

            if (stats[name]) {
              stats[name] = stats[name] + amount;
            } else {
              stats[name] = amount;
            }
          });

          for (let commander of war.ranks) {
            // Seed this history item with all commander names and colors
            if (!day.hasOwnProperty(commander.name)) {
              day[commander.name] = 0;
              day[`${commander.name}Color`] = commander.color;
            }
          }

          const commandersArr = Object.keys(stats);

          commandersArr.sort();

          for (let commander of commandersArr) {
            day[commander] = stats[commander];
          }
        });

        war.ranks.sort((a, b) => {
          return b.meta.score - a.meta.score;
        });
      }
    }

    this.setState({
      ...this.state,
      wars: warsInitial,
      ranks,
      selectedRanks: wars[0].ranks,
    });
    setTimeout(() => this.setState({ ...this.state, wars }), 500);

    SocketProvider.on("message", this.onMessage.bind(this));
  }

  async componentDidUpdate() {
    const sound = new window.Howl({
      src: "/sound/typing.mp3",
    });

    sound.play();
  }

  componentWillUnmount() {
    SocketProvider.off("message", this.onMessage);
  }

  render() {
    if (!this.state.wars.length || !this.state.wars[0].participation) {
      return <Loading />;
    }

    const rows = [];

    for (let i = 0; i < this.state.selectedRanks.length; i++) {
      let row = this.state.selectedRanks[i];

      rows.push(
        <tr className={this.props.classes.row}>
          <td>{i + 1}</td>
          <td>{row.name.toUpperCase()}</td>
          <td>{formatNumber(row.meta.score)}</td>
          <td>{(row.meta.score / 90000).toFixed(2)}</td>
          <td>{row.meta.total}</td>
        </tr>
      );
    }

    const wars = [];
    const warOptions = [];
    let warIndex = 0;

    for (let war of this.state.wars) {
      wars.push(
        <Row nested noMargin>
          <Col m={12} l={12}>
            <Highlight>
              <Project
                animate
                header={war.name}
                className={`${this.props.classes.project} ${this.props.classes.glow}`}
              >
                <Button
                  id="barToggle"
                  className={this.props.classes.toggle}
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      barToggle: !this.state.barToggle,
                    })
                  }
                >
                  Toggle View
                </Button>

                <div
                  className={`${this.props.classes.war} ${this.props.classes.glow}`}
                >
                  <div className={this.props.classes.barGraph}>
                    <BarChart
                      data={war.days}
                      keys={war.keys}
                      toggle={this.state.barToggle}
                      format={true}
                    />
                  </div>

                  <Line animate />

                  <Row>
                    <Col s={12} m={4}>
                      <h1>
                        <span style={{ opacity: "0.5" }}>System</span>
                        <br />
                        <span>{war.system}</span>
                      </h1>

                      <h1>
                        <span style={{ opacity: "0.5" }}>Support</span>
                        <br />
                        <span>{war.faction}</span>
                      </h1>

                      <h1>
                        <span style={{ opacity: "0.5" }}>Status</span>
                        <br />
                        <span>
                          {moment(war.endDate).isAfter(moment())
                            ? "Ongoing"
                            : "Ended"}
                        </span>
                      </h1>
                    </Col>

                    <Col s={12} m={8}>
                      <h1 style={{ marginBottom: "0.5rem" }}>
                        <span style={{ opacity: "0.5" }}>War Statement</span>
                      </h1>

                      <Words animate className={this.props.classes.words}>
                        {war.description}
                      </Words>
                    </Col>
                  </Row>
                </div>
              </Project>
            </Highlight>
          </Col>
        </Row>
      );

      warOptions.push(<option value={warIndex}>{war.name}</option>);

      warIndex++;
    }

    warOptions.push(<option value="all">All Time</option>);

    wars.push(
      <Row nested>
        <Col m={12} l={12}>
          <Highlight>
            <Project
              animate
              header="New Chonsu Alliance"
              className={`${this.props.classes.expiredProject} ${this.props.classes.glow}`}
            >
              <Button id="barToggle2" className={this.props.classes.toggle}>
                NO DATA
              </Button>

              <div>
                <Words animate className={this.props.classes.words}>
                  This war occurred before Straylight pilots were equipped with
                  flight recorders. Any data that is available remains
                  classified, but it is widely known in Moram that statistical
                  analysis would not have made a difference in the swift victory
                  that took place.
                </Words>
              </div>

              <Line animate />

              <div>
                <h1>
                  <p>War Statement</p>
                </h1>

                <Words animate className={this.props.classes.words}>
                  Straylight Systems declares war on the Federation group known
                  as "New Chonsu Alliance." The NCA currently occupies outpost
                  Ziemianski Dock in the system of Moram. Resolution of the war
                  in our favor will result in a takeover of said asset. The war
                  is expected to last at least four full days (Moram time.)
                </Words>
              </div>
            </Project>
          </Highlight>
        </Col>
      </Row>
    );

    return (
      <Row nested>
        <Col m={12} l={6}>
          {wars}
        </Col>

        <Col m={12} l={6}>
          <Row nested noMargin>
            <Col m={12} l={4}>
              <Highlight>
                <Project
                  animate
                  header="Squadron"
                  className={`${this.props.classes.project} ${this.props.classes.glow}`}
                >
                  <PieChart
                    data={this.state.wars[0].participation}
                    height={107}
                  />
                </Project>
              </Highlight>
            </Col>

            <Col m={12} l={8}>
              <Highlight>
                <Project
                  animate
                  header="Stats"
                  className={`${this.props.classes.project} ${this.props.classes.glow}`}
                >
                  <section className={this.props.classes.stats}>
                    <StatDisplay
                      label="total wars"
                      value={this.state.wars.length + 1}
                    />
                    <StatDisplay
                      label="bonds issued"
                      value={formatNumber(
                        sumBy(this.state.ranks, "meta.score")
                      )}
                    />
                  </section>
                </Project>
              </Highlight>
            </Col>
          </Row>

          <Row nested noMargin>
            <Col m={12}>
              <Highlight>
                <Project
                  animate
                  header="BONDS"
                  className={this.props.classes.project}
                >
                  <select
                    onChange={this.onSetWarTable.bind(this)}
                    className={this.props.classes.select}
                  >
                    {warOptions}
                  </select>

                  <Table animate>
                    <table>
                      <thead>
                        <tr>
                          <th>RANK</th>
                          <th>CMDR</th>
                          <th>BONDS</th>
                          <th>EST KILLS</th>
                          <th>TURN-INS</th>
                        </tr>
                      </thead>

                      <tbody>{rows}</tbody>
                    </table>
                  </Table>
                </Project>
              </Highlight>
            </Col>
          </Row>

          <Row nested>
            <Col m={12}>
              <Highlight>
                <Project
                  animate
                  header="Info"
                  className={`${this.props.classes.project} ${this.props.classes.glow}`}
                >
                  <Words animate className={this.props.classes.words}>
                    Graph statistics reflect combat bonds turned during each
                    active day of war. Leaderboard reflects all-time statistics
                    for combat bonds turned in by each commander.
                  </Words>

                  <Words animate className={this.props.classes.words}>
                    Combat bonds are recorded on cash-in, not on accrual.
                  </Words>

                  <Words animate className={this.props.classes.words}>
                    Since v1.2.0, your flight recorder updates leaderboard data
                    in real-time. This data slate interface is only updated
                    during approved wars.
                  </Words>
                </Project>
              </Highlight>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default withStyles(style)(Conflicts);
