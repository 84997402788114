import React from "react";
import {
  Button,
  Col,
  Highlight,
  Loading,
  Project,
  List,
  Row,
  Table,
  Words,
  withStyles,
  Frame,
} from "arwes";
import moment from "moment";
import { darken } from "polished";

import SocketProvider from "../providers/socket";
import SessionProvider from "../providers/session";
import MiniBarChart from "../components/miniBarChart";
import StatDisplay from "../components/statDisplay";
import formatNumber from "../util/formatNumber";
import conversation from "../util/conversation";

const { timeOfDay, initialGreeting } = conversation;

const style = (theme) => ({
  row: {
    "&:hover": {
      background: "#FF6600",
      color: "#000000",
    },
  },
  project: {
    marginTop: "1rem",
    userSelect: "none",
  },
  stats: {
    display: "flex",
    justifyContent: "space-around",
  },
  words: {
    marginBottom: "1rem",
    fontSize: "1.1rem",
  },
  wordsWrap: {
    marginBottom: "1rem",
    marginTop: "0.75rem",
    fontSize: "1.1rem",
    whiteSpace: "pre-wrap",
  },
  toggle: {
    position: "absolute",
    top: "19.5px",
    right: "19.5px",
  },
  glow: {
    "&:hover": {
      filter: "brightness(120%)",
    },
  },
  barGraph: {
    marginBottom: "0rem",
    marginTop: "1rem",
  },
  select: {
    backgroundColor: "black",
    color: "#FF6600",
    borderColor: "rgba(153,61,0,0.65)",
    textTransform: "uppercase",
    padding: "12px 18px",
    mozAppearance: "none",
    webkitAppearance: "none",
    appearance: "none",
    position: "absolute",
    top: "15px",
    right: "12px",
    fontFamily: "Eurostile Regular",
    fontSize: "18px",
    fontWeight: "600",
    outline: "none",
    transition: "175ms borderColor ease",
    "&:hover": {
      borderColor: "#FF6600",
    },
  },
  option: {
    "&:checked)": {
      backgroundColor: "#FF6600",
    },
  },
});

let pilotCount = 0;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      barToggle: true,
      graph: {
        keys: [],
      },
    };
  }

  _resetColorFn() {
    pilotCount = 0;
  }

  _colorFn() {
    const value = pilotCount;
    const color = darken(value, "#FF6600");

    pilotCount = pilotCount + 0.05;

    return color;
  }

  _buildMissionGraph(ticks, missionStats) {
    const graph = {
      data: [],
      keys: [],
    };

    for (const tick of ticks) {
      const tickData = {};

      // Store the time
      tickData.updated_at = tick.time;

      for (let commander of missionStats.ranks) {
        tickData[commander.name] = 0;

        // Assign CMDR colors
        tickData[`${commander.name}Color`] = this._colorFn(commander.name);
      }

      for (let commander of tick.missionStats.ranks) {
        tickData[commander.name] = commander.meta.rewardCredits;

        // Build graph keys
        if (!graph.keys.find((name) => name === commander.name)) {
          graph.keys.push(commander.name);
        }
      }

      this._resetColorFn();

      graph.data.push(tickData);
    }

    return graph;
  }

  _buildMissionGraphKills(ticks, missionStats) {
    const graph = {
      data: [],
      keys: [],
    };

    for (const tick of ticks) {
      const tickData = {};

      // Store the time
      tickData.updated_at = tick.time;

      for (let commander of missionStats.ranks) {
        tickData[commander.name] = 0;

        // Assign CMDR colors
        tickData[`${commander.name}Color`] = this._colorFn(commander.name);
      }

      for (let commander of tick.missionStats.ranks) {
        tickData[commander.name] = commander.meta.killCount;

        // Build graph keys
        if (!graph.keys.find((name) => name === commander.name)) {
          graph.keys.push(commander.name);
        }
      }

      this._resetColorFn();

      graph.data.push(tickData);
    }

    return graph;
  }

  _buildTradeGraph(ticks, tradeStats) {
    const graph = {
      data: [],
      keys: [],
    };

    for (const tick of ticks) {
      const tickData = {};

      // Store the time
      tickData.updated_at = tick.time;

      for (let commander of tradeStats.ranks) {
        tickData[commander.name] = 0;

        // Assign CMDR colors
        tickData[`${commander.name}Color`] = this._colorFn(commander.name);
      }

      for (let commander of tick.tradeStats.ranks) {
        tickData[commander.name] = commander.meta.score;

        // Build graph keys
        if (!graph.keys.find((name) => name === commander.name)) {
          graph.keys.push(commander.name);
        }
      }

      this._resetColorFn();

      graph.data.push(tickData);
    }

    return graph;
  }

  onMessage(records) {
    let record = records.length ? records[0] : records;
    const { ticks } = this.state;

    if (record && record.missionId) {
      // Let's be sure this is a mission
      const mission = record;

      if (
        mission.isCompleted &&
        mission.data?.missionName?.indexOf("Massacre") !== -1
      ) {
        const relevantTick = ticks[ticks.length - 1];
        const { missionStats, objective } = this.state;

        const factionEffect = mission.data.minorfactionEffects.find(
          (effect) => {
            const isPrimary = effect.minorfactionName === objective.faction;
            let isSecondary = false;

            for (const so of objective.secondaryObjectives) {
              if (so.faction === effect.minorfactionName) {
                isSecondary = true;
              }
            }

            return isPrimary || isSecondary;
          }
        );

        let tickCommander = relevantTick.missionStats.ranks.find(
          (commander) => commander.name === mission.commander.name
        );
        let statsCommander = missionStats.ranks.find(
          (commander) => commander.name === mission.commander.name
        );

        // Prepare individual commander stats
        if (tickCommander) {
          tickCommander.meta.rewardCredits =
            tickCommander.meta.rewardCredits + mission.data.rewardCredits;
          tickCommander.meta.killCount =
            tickCommander.meta.killCount + mission.data.killCount;
          tickCommander.meta.total++;
        } else {
          tickCommander = {
            ...mission.commander,
            meta: {
              rewardCredits: mission.data.rewardCredits,
              killCount: mission.data.killCount,
              total: 1,
            },
          };

          relevantTick.missionStats.ranks.push(tickCommander);
        }

        // Prepare global commander stats
        if (statsCommander) {
          statsCommander.meta.rewardCredits =
            statsCommander.meta.rewardCredits + mission.data.rewardCredits;
          statsCommander.meta.killCount =
            statsCommander.meta.killCount + mission.data.killCount;
          statsCommander.meta.total++;
        } else {
          statsCommander = {
            ...mission.commander,
            meta: {
              rewardCredits: mission.data.rewardCredits,
              killCount: mission.data.killCount,
              total: 1,
            },
          };

          missionStats.ranks.push(statsCommander);
        }

        // Tick stats
        relevantTick.missionStats.killCount =
          relevantTick.missionStats.killCount + mission.data.killCount;
        relevantTick.missionStats.rewardCredits =
          relevantTick.missionStats.rewardCredits + mission.data.rewardCredits;
        relevantTick.missionStats.total++;

        // Global stats for the whole campaign
        missionStats.killCount =
          missionStats.killCount + mission.data.killCount;
        missionStats.rewardCredits =
          missionStats.rewardCredits + mission.data.rewardCredits;
        missionStats.total++;

        // Max, for graphing
        const rewardCreditsMax = relevantTick.missionStats.rewardCredits;

        if (rewardCreditsMax > missionStats.rewardCreditsMax) {
          missionStats.rewardCreditsMax = rewardCreditsMax;
        }

        const killCountMax = relevantTick.missionStats.killCount;

        if (killCountMax > missionStats.killCountMax) {
          missionStats.killCountMax = killCountMax;
        }

        const missionGraph = this._buildMissionGraph(ticks, missionStats);
        const killGraph = this._buildMissionGraphKills(ticks, missionStats);

        this.setState({
          ...this.state,
          ticks,
          missionStats,
          missionGraph,
          killGraph,
        });
      }
    }

    // TRADE
    if (record && record.data && record.data.average) {
      // trades
      const trade = record;
      const { objective } = this.state;
      const relevantTarget = objective.tradeTargets.find(
        (target) =>
          target.stationName === trade.data.stationName &&
          target.starsystemName === trade.data.starsystemName
      );

      if (relevantTarget) {
        const relevantTick = ticks[ticks.length - 1];
        const { tradeStats } = this.state;

        if (relevantTick) {
          let tickCommander = relevantTick.tradeStats.ranks.find(
            (commander) => commander.name === trade.commander.name
          );
          let statsCommander = tradeStats.ranks.find(
            (commander) => commander.name === trade.commander.name
          );
          let targetCommander = relevantTarget.tradeStats.ranks.find(
            (commander) => commander.name === trade.commander.name
          );

          const {
            data: { price, average, count },
          } = trade;
          const difference = price - average;
          const isValidEffect = Math.abs(difference) >= 2000;
          const scoreDelta = isValidEffect ? difference * count : 0;
          const totalDelta = count;

          // Prepare individual commander stats
          if (tickCommander) {
            tickCommander.meta.score =
              tickCommander.meta.score + Math.abs(scoreDelta);
            tickCommander.meta.total = tickCommander.meta.total + totalDelta;
          } else {
            tickCommander = {
              ...trade.commander,
              meta: {
                score: isValidEffect ? Math.abs(scoreDelta) : 0,
                total: totalDelta,
              },
            };

            relevantTick.tradeStats.ranks.push(tickCommander);
          }

          // Prepare global commander stats
          if (statsCommander) {
            statsCommander.meta.score =
              statsCommander.meta.score + Math.abs(scoreDelta);
            statsCommander.meta.total = statsCommander.meta.total + totalDelta;
          } else {
            statsCommander = {
              ...trade.commander,
              meta: {
                score: isValidEffect ? Math.abs(scoreDelta) : 0,
                total: totalDelta,
              },
            };

            tradeStats.ranks.push(statsCommander);
          }

          // Prepare target commander stats
          if (targetCommander) {
            targetCommander.meta.score =
              targetCommander.meta.score + Math.abs(scoreDelta);
            targetCommander.meta.total =
              targetCommander.meta.total + totalDelta;
          } else {
            targetCommander = {
              ...trade.commander,
              meta: {
                score: isValidEffect ? Math.abs(scoreDelta) : 0,
                total: totalDelta,
              },
            };

            relevantTarget.tradeStats.ranks.push(targetCommander);
          }

          // Tick stats
          relevantTick.tradeStats.score =
            relevantTick.tradeStats.score + Math.abs(scoreDelta);
          relevantTick.tradeStats.total =
            relevantTick.tradeStats.total + totalDelta;

          // Global stats for the whole campaign
          tradeStats.score = tradeStats.score + Math.abs(scoreDelta);
          tradeStats.total = tradeStats.total + totalDelta;

          // Target stats
          relevantTarget.tradeStats.score =
            relevantTarget.tradeStats.score + Math.abs(scoreDelta);
          relevantTarget.tradeStats.total =
            relevantTarget.tradeStats.total + totalDelta;

          // Add the trade to that tick
          // relevantTick.trades.push(trade);

          // Max, for graphing
          const tickMax = relevantTick.tradeStats.score;

          if (tickMax > tradeStats.max) {
            tradeStats.max = tickMax;
          }

          const tradeGraph = this._buildTradeGraph(ticks, tradeStats);

          this.setState({
            ...this.state,
            ticks,
            tradeStats,
            tradeGraph,
          });
        }
      }
    }
  }

  async componentDidMount() {
    const { missionStats, tradeStats, killStats, ticks, objective } =
      await SocketProvider.get("/dashboard");
    const tick = ticks[ticks.length - 1];
    await SocketProvider.get("/subscribe/missions");
    await SocketProvider.get("/subscribe/marketSells");

    // Build graphs
    const missionGraph = this._buildMissionGraph(ticks, missionStats);
    const killGraph = this._buildMissionGraphKills(ticks, missionStats);
    const tradeGraph = this._buildTradeGraph(ticks, tradeStats);

    // Sort ranks
    missionStats.ranks.sort((a, b) => {
      return b.meta.score - a.meta.score;
    });

    tradeStats.ranks.sort((a, b) => {
      return b.meta.score - a.meta.score;
    });

    const { tradeTargets } = objective;
    const tradeTargetWidth = 8 / tradeTargets.length;

    this.setState({
      missionStats,
      tradeStats,
      // killStats,
      ticks,
      tick,
      tradeGraph,
      missionGraph,
      killGraph,
      objective,
      isLoading: false,
      tradeTargetWidth,
      greeting: initialGreeting(),
    });

    SocketProvider.on("message", this.onMessage.bind(this));
  }

  async componentDidUpdate() {
    const sound = new window.Howl({
      src: "/sound/typing.mp3",
    });

    sound.play();
  }

  componentWillUnmount() {
    SocketProvider.off("message", this.onMessage);
  }

  render() {
    if (!this.state.tick || !this.state.objective) {
      return <Loading />;
    }

    const session = SessionProvider.get();
    const { profile } = session;
    const {
      objective,
      barToggle,
      missionStats,
      tradeStats,
      // killStats,
      missionGraph,
      killGraph,
      tradeGraph,
      tradeTargetWidth,
    } = this.state;

    const greeting = `Welcome, Commander. ${
      this.state.greeting
    } ${timeOfDay()}. Here is your daily briefing.`;

    const missionRowsDay = [];
    const missionRowsAll = [];
    const tradeRowsDay = [];
    const tradeRowsAll = [];

    const { time } = this.state.tick;
    const isTickLate = moment(time).valueOf() > moment().valueOf();
    const tickDisplay = moment(time).add(1, "days").toNow(true).toUpperCase();

    const tradeTargetDOM = [];

    for (const tradeTarget of objective.tradeTargets) {
      tradeTargetDOM.push(
        <Col s={12} m={12} l={4}>
          <Highlight>
            <Project
              animate
              header={`Trades / ${tradeTarget.stationName.split(" ")[0]}`}
              className={`${this.props.classes.project} ${this.props.classes.glow}`}
            >
              <section className={this.props.classes.stats}>
                <StatDisplay
                  label="tonnes of cargo"
                  value={formatNumber(tradeTarget.tradeStats.total)}
                />
                <StatDisplay
                  label="total impact"
                  value={`${formatNumber(
                    Math.abs(tradeTarget.tradeStats.score)
                  )}`}
                />
              </section>
            </Project>
          </Highlight>
        </Col>
      );
    }

    this.state.ticks[this.state.ticks.length - 1].missionStats.ranks.sort(
      (a, b) => {
        return b.meta.rewardCredits - a.meta.rewardCredits;
      }
    );

    this.state.missionStats.ranks.sort((a, b) => {
      return b.meta.rewardCredits - a.meta.rewardCredits;
    });

    for (
      let i = 0;
      i <
      this.state.ticks[this.state.ticks.length - 1].missionStats.ranks.length;
      i++
    ) {
      const row =
        this.state.ticks[this.state.ticks.length - 1].missionStats.ranks[i];

      missionRowsDay.push(
        <tr className={this.props.classes.row}>
          <td>{i + 1}</td>
          <td>{row.name.toUpperCase()}</td>
          <td>{formatNumber(row.meta.rewardCredits)}</td>
          <td>{row.meta.killCount}</td>
          <td>{row.meta.total}</td>
        </tr>
      );
    }

    for (let i = 0; i < this.state.missionStats.ranks.length; i++) {
      const row = this.state.missionStats.ranks[i];

      missionRowsAll.push(
        <tr className={this.props.classes.row}>
          <td>{i + 1}</td>
          <td>{row.name.toUpperCase()}</td>
          <td>{formatNumber(row.meta.rewardCredits)}</td>
          <td>{row.meta.killCount}</td>
          <td>{row.meta.total}</td>
        </tr>
      );
    }

    this.state.ticks[this.state.ticks.length - 1].tradeStats.ranks.sort(
      (a, b) => {
        return b.meta.score - a.meta.score;
      }
    );

    for (
      let i = 0;
      i < this.state.ticks[this.state.ticks.length - 1].tradeStats.ranks.length;
      i++
    ) {
      const row =
        this.state.ticks[this.state.ticks.length - 1].tradeStats.ranks[i];

      tradeRowsDay.push(
        <tr className={this.props.classes.row}>
          <td>{i + 1}</td>
          <td>{row.name.toUpperCase()}</td>
          <td>{formatNumber(row.meta.score)}</td>
          <td>{formatNumber(row.meta.total)}</td>
        </tr>
      );
    }

    for (let i = 0; i < this.state.tradeStats.ranks.length; i++) {
      const row = this.state.tradeStats.ranks[i];

      tradeRowsAll.push(
        <tr className={this.props.classes.row}>
          <td>{i + 1}</td>
          <td>{row.name.toUpperCase()}</td>
          <td>{formatNumber(row.meta.score)}</td>
          <td>{formatNumber(row.meta.total)}</td>
        </tr>
      );
    }

    const graphDOM = [];

    graphDOM.push(
      <Row nested noMargin>
        <Col s={12} m={12} l={4}>
          <Highlight>
            <Frame
              animate={true}
              corners={4}
              className={this.props.classes.barGraph}
            >
              <MiniBarChart
                data={missionGraph.data}
                keys={missionGraph.keys}
                toggle={barToggle}
                format={true}
                legend={{ left: "CREDITS" }}
                max={missionStats.rewardCreditsMax}
              />
            </Frame>
          </Highlight>
        </Col>

        <Col s={12} m={12} l={4}>
          <Highlight>
            <Frame
              animate={true}
              corners={4}
              className={this.props.classes.barGraph}
            >
              <MiniBarChart
                data={killGraph.data}
                keys={killGraph.keys}
                toggle={barToggle}
                format={true}
                legend={{ left: "KILLS" }}
                max={missionStats.killCountMax}
              />
            </Frame>
          </Highlight>
        </Col>

        <Col s={12} m={12} l={4}>
          <Highlight>
            <Frame
              animate={true}
              corners={4}
              className={this.props.classes.barGraph}
            >
              <MiniBarChart
                data={tradeGraph.data}
                keys={tradeGraph.keys}
                toggle={barToggle}
                format={true}
                legend={{ left: "CREDITS" }}
                max={tradeStats.max}
              />
            </Frame>
          </Highlight>
        </Col>
      </Row>
    );

    const missionBoardDay = this.state.isLoadingLeaderboard ? (
      <Loading />
    ) : (
      <Table animate>
        <table>
          <thead>
            <tr>
              <th>RANK</th>
              <th>CMDR</th>
              <th>CREDITS</th>
              <th>KILLS</th>
              <th>MISSIONS</th>
            </tr>
          </thead>

          <tbody>
            {missionRowsDay}
            {missionRowsDay?.length === 0 && (
              <p>
                <br></br>Nothing yet.
              </p>
            )}
          </tbody>
        </table>
      </Table>
    );

    const missionBoardAll = this.state.isLoadingLeaderboard ? (
      <Loading />
    ) : (
      <Table animate>
        <table>
          <thead>
            <tr>
              <th>RANK</th>
              <th>CMDR</th>
              <th>CREDITS</th>
              <th>KILLS</th>
              <th>MISSIONS</th>
            </tr>
          </thead>

          <tbody>
            {missionRowsAll}
            {missionRowsAll?.length === 0 && (
              <p>
                <br></br>Nothing yet.
              </p>
            )}
          </tbody>
        </table>
      </Table>
    );

    const tradeBoardDay = this.state.isLoadingLeaderboard ? (
      <Loading />
    ) : (
      <Table animate>
        <table>
          <thead>
            <tr>
              <th>RANK</th>
              <th>CMDR</th>
              <th>IMPACT</th>
              <th>TONNES</th>
            </tr>
          </thead>

          <tbody>{tradeRowsDay}</tbody>
        </table>
      </Table>
    );

    const tradeBoardAll = this.state.isLoadingLeaderboard ? (
      <Loading />
    ) : (
      <Table animate>
        <table>
          <thead>
            <tr>
              <th>RANK</th>
              <th>CMDR</th>
              <th>IMPACT</th>
              <th>TONNES</th>
            </tr>
          </thead>

          <tbody>{tradeRowsAll}</tbody>
        </table>
      </Table>
    );

    return (
      <div>
        <Row nested noMargin>
          <Col s={12} m={12} l={12} xl={9}>
            <Highlight>
              <Project
                animate
                header={`Current Focus: ${objective.title}`}
                className={`${this.props.classes.project} ${this.props.classes.glow}`}
              >
                <section className={this.props.classes.stats}>
                  <StatDisplay
                    label={
                      objective.isAlliedTarget
                        ? "support this faction"
                        : "nuke this faction"
                    }
                    value={objective.faction.toUpperCase()}
                  />
                  <StatDisplay
                    label="target system"
                    value={objective.system.toUpperCase()}
                  />
                  <StatDisplay
                    label={
                      isTickLate ? "since expected tick" : "until next dispatch"
                    }
                    value={tickDisplay}
                  />
                </section>
              </Project>
            </Highlight>

            <Row nested noMargin>
              <Col s={12} m={12} l={4}>
                <Highlight>
                  <Project
                    animate
                    header="CREDITS"
                    className={`${this.props.classes.project} ${this.props.classes.glow}`}
                  >
                    <section className={this.props.classes.stats}>
                      <StatDisplay
                        label="total"
                        value={formatNumber(missionStats.rewardCredits)}
                      />
                      <StatDisplay
                        label="today"
                        value={formatNumber(
                          this.state.ticks[this.state.ticks.length - 1]
                            .missionStats.rewardCredits
                        )}
                      />
                    </section>
                  </Project>
                </Highlight>
              </Col>

              <Col s={12} m={12} l={4}>
                <Highlight>
                  <Project
                    animate
                    header="Kills"
                    className={`${this.props.classes.project} ${this.props.classes.glow}`}
                  >
                    <section className={this.props.classes.stats}>
                      <StatDisplay
                        label="total"
                        value={formatNumber(missionStats.killCount)}
                      />
                      <StatDisplay
                        label="today"
                        value={formatNumber(
                          this.state.ticks[this.state.ticks.length - 1]
                            .missionStats.killCount
                        )}
                      />
                    </section>
                  </Project>
                </Highlight>
              </Col>

              {tradeTargetDOM}
            </Row>

            {graphDOM}

            <Row nested noMargin>
              <Col s={12} m={12} l={6}>
                <Highlight>
                  <Project
                    animate
                    header="LEADERBOARD / Total"
                    className={this.props.classes.project}
                  >
                    {missionBoardAll}
                  </Project>
                </Highlight>
              </Col>
              <Col s={12} m={12} l={6}>
                <Highlight>
                  <Project
                    animate
                    header="LEADERBOARD / Today"
                    className={this.props.classes.project}
                  >
                    {missionBoardDay}
                  </Project>
                </Highlight>
              </Col>
            </Row>

            <Row nested noMargin>
              <Col s={12} m={12} l={6}>
                <Highlight>
                  <Project
                    animate
                    header="TRADES / Current"
                    className={this.props.classes.project}
                  >
                    {tradeBoardDay}
                  </Project>
                </Highlight>
              </Col>

              <Col s={12} m={12} l={6}>
                <Highlight>
                  <Project
                    animate
                    header="TRADES / Last week"
                    className={this.props.classes.project}
                  >
                    {tradeBoardAll}
                  </Project>
                </Highlight>
              </Col>
            </Row>
          </Col>

          <Col s={12} m={12} l={12} xl={3}>
            <Highlight>
              <Project
                animate
                header={`Authorized: ${profile.username}`}
                className={this.props.classes.project}
              >
                <Words animate className={this.props.classes.words}>
                  {greeting}
                </Words>
              </Project>
            </Highlight>

            <Highlight>
              <Project
                animate
                header="Objectives"
                className={`${this.props.classes.project} ${this.props.classes.glow}`}
              >
                <Words animate className={this.props.classes.wordsWrap}>
                  {objective.description}
                </Words>
              </Project>
            </Highlight>

            {/* <Highlight>
              <Project
                animate
                header="Kill Record"
                className={`${this.props.classes.project} ${this.props.classes.glow}`}
              >
                <section className={this.props.classes.stats}>
                  <StatDisplay
                    label="SLGT vs. IMCR"
                    value={`${killStats.for} - ${killStats.against}`}
                  />
                </section>
              </Project>
            </Highlight> */}

            <Highlight>
              <Project
                animate
                header="Info"
                className={`${this.props.classes.project} ${this.props.classes.glow}`}
              >
                <Words animate className={this.props.classes.words}>
                  This interface presents data relevant to the current squadron
                  objective(s) as designated by the Directorate.
                </Words>

                <Words animate className={this.props.classes.words}>
                  Credits refer to mission reward credits paid out by Straylight
                  Systems. Kills refer to sanctioned kill orders accompanying
                  missions provided by Straylight, and do not include additional
                  kills outside these missions. Those kills were motivated by
                  you, and you alone, and we shall bear no responsibility.
                </Words>

                <Words animate className={this.props.classes.words}>
                  Your flight recorder updates all of this shit in real-time.
                  You're welcome.
                </Words>

                <Words animate className={this.props.classes.words}>
                  Please donate to Chad's OnlyFans.
                </Words>
              </Project>
            </Highlight>

            <Highlight>
              <Project
                animate
                header="Resources"
                className={this.props.classes.project}
              >
                <Row>
                  <Col s={12}>
                    <List node="ul">
                      <li>
                        <a
                          href="https://github.com/chadvangaalen/sfr"
                          target="_blank"
                        >
                          Download SFR
                        </a>
                      </li>

                      {/* <li>
                        <a
                          href="https://helix.straylight.systems"
                          target="_blank"
                        >
                          Access Helix
                        </a>

                        <span> (pw: yashkyknowsbest)</span>
                      </li> */}

                      <li>
                        <a
                          href="https://inara.cz/squadron/10684/"
                          target="_blank"
                        >
                          Register on Inara
                        </a>
                      </li>
                    </List>
                  </Col>
                </Row>
              </Project>
            </Highlight>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withStyles(style)(Dashboard);
