import React from "react";
import { ResponsiveLine } from "@nivo/line";
import { withStyles } from "arwes";
import moment from "moment";
import { minBy } from "lodash";

import chartTheme from "../util/chartTheme";

const style = (theme) => ({
  root: {
    height: "360px",
    color: "#FF6600",
  },
});

const getMinimumValue = (data) => {
  const value = minBy(data, (item) => {
    return Number(item.y);
  });

  return value.y;
};

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const LineChart = withStyles(style)(({ classes, data }) => (
  <div className={classes.root}>
    <ResponsiveLine
      data={data}
      margin={{ top: 30, right: 45, bottom: 30, left: 45 }}
      xScale={{ type: "point" }}
      xFormat={(datum) => moment(datum).format("MMM DD")}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        stacked: false,
        reverse: false,
      }}
      curve="monotoneX"
      enableArea={false}
      areaBaselineValue={getMinimumValue(data[0].data)}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: "bottom",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        format: (value) => moment(value).format("MMM DD").toUpperCase(),
      }}
      axisLeft={{
        orient: "left",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "% INF",
        legendOffset: -40,
        legendPosition: "middle",
      }}
      colors={"#f60"}
      pointSize={0}
      pointLabel="y"
      pointLabelYOffset={-12}
      useMesh={true}
      enableGridX={false}
      enableGridY={false}
      theme={chartTheme}
      tooltip={({
        point: {
          serieId: id,
          color,
          data: { y },
        },
      }) => {
        return (
          <strong
            style={{ color, fontSize: "12px", textTransform: "uppercase" }}
          >
            {id}: {y}
          </strong>
        );
      }}
    />
  </div>
));

export default LineChart;
