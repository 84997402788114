export default function formatNumber(value) {
  if (!value) {
    return "0";
  }

  let newValue = value;

  if (value >= 1000) {
    let suffixes = ["", "K", "M", "B", "T"];
    let suffixNum = Math.floor(("" + value).length / 3);
    let shortValue = "";

    for (let precision = 4; precision >= 1; precision--) {
      shortValue = parseFloat(
        (suffixNum !== 0
          ? value / Math.pow(1000, suffixNum)
          : value
        ).toPrecision(precision)
      );
      let dotLessShortValue = (shortValue + "").replace(/[^a-zA-Z 0-9]+/g, "");
      if (dotLessShortValue.length <= 4) {
        break;
      }
    }

    shortValue = shortValue.toFixed(2);

    newValue = shortValue + suffixes[suffixNum];
  }

  return newValue;
}
