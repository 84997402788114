import React from "react";
import {
  Highlight,
  Loading,
  Project,
  Table,
  Row,
  Col,
  withStyles,
} from "arwes";
import moment from "moment";
import formatNumber from "../util/formatNumber";
import StatDisplay from "../components/statDisplay";
import SocketProvider from "../providers/socket";

const style = (theme) => ({
  row: {
    "&:hover": {
      background: "#FF6600",
      color: "#000000",
    },
  },
  rowInvalid: {
    opacity: 0.5,
  },
  project: {
    marginTop: "1rem",
    userSelect: "none",
  },
  stats: {
    display: "flex",
    justifyContent: "space-around",
  },
  words: {
    marginBottom: "1rem",
  },
});

class Campaigns extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      objectives: [],
      tick: {},
    };
  }

  async componentDidMount() {
    const { objectives, tick } = await SocketProvider.get("/objectives");
    await SocketProvider.get("/subscribe/marketSells");

    this.setState({
      ...this.state,
      objectives,
      tick,
    });

    SocketProvider.on("message", this.onMessage.bind(this));
  }

  async componentDidUpdate() {
    const sound = new window.Howl({
      src: "/sound/typing.mp3",
    });

    sound.play();
  }

  componentWillUnmount() {
    SocketProvider.off("message", this.onMessage);
  }

  onMessage(records) {
    let record = records.length ? records[0] : records;

    if (record && record.data && record.data.average) {
      // make sure it's a trade
      const { objectives } = this.state;

      for (const objective of objectives) {
        for (const tradeTarget of objective.tradeTargets) {
          if (
            tradeTarget.stationName === record.data.stationName &&
            tradeTarget.starsystemName === record.data.starsystemName
          ) {
            const {
              data: { price, average, count },
            } = record;
            const difference = price - average;
            const isValidEffect = Math.abs(difference) >= 2000;
            const effect = isValidEffect ? difference * count : 0;

            // Update rank scores
            const rankCommanderIndex = tradeTarget.ranks.findIndex(
              (commander) => commander.id === record.commander.id
            );

            if (rankCommanderIndex !== -1) {
              const rankCommander = tradeTarget.ranks[rankCommanderIndex];

              rankCommander.meta.score =
                rankCommander.meta.score + Math.abs(effect);
              rankCommander.meta.total = rankCommander.meta.total + 1;
            } else {
              tradeTarget.ranks.push({
                ...record.commander,
                meta: {
                  score: Math.abs(effect),
                  total: 1,
                },
              });
            }

            tradeTarget.trades.unshift(record);
          }
        }
      }

      this.setState({
        ...this.state,
        objectives,
      });
    }
  }

  render() {
    if (!this.state.objectives.length) {
      return <Loading />;
    }

    const tradeTargets = [];
    const tickStatus = [];
    const allTrades = [];
    let leaderboard = [];
    const leaderboardRows = [];
    let activeObjective;

    for (const objective of this.state.objectives) {
      activeObjective = objective;

      for (const tradeTarget of objective.tradeTargets) {
        let total = 0;
        let count = 0;

        for (const rank of tradeTarget.ranks) {
          const existingRank = leaderboard.find(
            (commander) => commander.id === rank.id
          );

          if (existingRank) {
            existingRank.meta.score = existingRank.meta.score + rank.meta.score;
            existingRank.meta.total = existingRank.meta.total + rank.meta.total;
          } else {
            leaderboard.push(rank);
          }
        }

        for (const trade of tradeTarget.trades) {
          const delta = trade.data.price - trade.data.average;
          const totalDelta = delta * trade.data.count;
          const isValid = Math.abs(delta) >= 2000;
          const isNegative = Math.sign(delta) === -1;

          if (isValid) {
            total = total + totalDelta;
            count = count + trade.data.count;
          }

          allTrades.push(
            <tr
              className={
                isValid ? this.props.classes.row : this.props.classes.rowInvalid
              }
            >
              <td>
                {moment(trade.updatedAt)
                  .utc()
                  .add(1286, "years")
                  .format("YYYY")}
                -{moment(trade.updatedAt).utc().format("MM-DD HH:mm:ss")}
              </td>
              <td>{trade.commander.name.toUpperCase()}</td>
              <td>{trade.data.starsystemName}</td>
              <td>{trade.data.stationName}</td>
              <td>{trade.data.type.toUpperCase()}</td>
              <td>{trade.data.count}</td>
              <td>{formatNumber(trade.data.price)}</td>
              <td>{formatNumber(trade.data.average)}</td>
              <td>
                {isNegative ? "-" : "+"}
                {formatNumber(Math.abs(totalDelta))}
              </td>
            </tr>
          );
        }

        tradeTargets.push(
          <Col s={12} m={12} l={4}>
            <Highlight>
              <Project
                animate
                header={`${tradeTarget.stationName} / ${tradeTarget.starsystemName}`}
                className={`${this.props.classes.project} ${this.props.classes.glow}`}
              >
                <section className={this.props.classes.stats}>
                  <StatDisplay
                    label="tonnes of cargo"
                    value={formatNumber(count)}
                  />
                  <StatDisplay
                    label="total impact"
                    value={`${
                      Math.sign(total) === -1 ? "-" : "+"
                    }${formatNumber(Math.abs(total))}`}
                  />
                </section>
              </Project>
            </Highlight>
          </Col>
        );
      }

      const { time } = this.state.tick;
      const isTickLate = moment(time).valueOf() > moment().valueOf();
      const tickDisplay = moment(time).add(1, "days").toNow(true).toUpperCase();

      tickStatus.push(
        <Col s={12} m={12} l={4}>
          <Highlight>
            <Project
              animate
              header="Status"
              className={`${this.props.classes.project} ${this.props.classes.glow}`}
            >
              <section className={this.props.classes.stats}>
                <StatDisplay
                  label={
                    isTickLate ? "since expected tick" : "until next dispatch"
                  }
                  value={tickDisplay}
                />
              </section>
            </Project>
          </Highlight>
        </Col>
      );
    }

    leaderboard.sort((a, b) => {
      return b.meta.score - a.meta.score;
    });

    for (let i = 0; i < leaderboard.length; i++) {
      const row = leaderboard[i];

      leaderboardRows.push(
        <tr className={this.props.classes.row}>
          <td>{i + 1}</td>
          <td>{row.name.toUpperCase()}</td>
          <td>{formatNumber(row.meta.score)}</td>
        </tr>
      );
    }

    return (
      <div>
        <Row nested noMargin>
          <Col s={12} m={12} l={12}>
            <Row nested noMargin>
              {tradeTargets}

              {tickStatus}
            </Row>
          </Col>
        </Row>

        <Row nested noMargin>
          <Col s={12} m={12} l={8}>
            <Highlight>
              <Project
                animate
                header="Trades since last dispatch"
                className={this.props.classes.project}
              >
                <Table animate>
                  <table>
                    <thead>
                      <tr>
                        <th>TIMESTAMP</th>
                        <th>CMDR</th>
                        <th>SYSTEM</th>
                        <th>STATION</th>
                        <th>TYPE</th>
                        <th>#</th>
                        <th>SELL</th>
                        <th>BUY</th>
                        <th>TOTAL</th>
                      </tr>
                    </thead>

                    <tbody>{allTrades}</tbody>
                  </table>
                </Table>
              </Project>
            </Highlight>
          </Col>

          <Col s={12} m={12} l={4}>
            <Highlight>
              <Project
                animate
                header="Leaders"
                className={this.props.classes.project}
              >
                <Table animate>
                  <table>
                    <thead>
                      <tr>
                        <th>RANK</th>
                        <th>CMDR</th>
                        <th>EFFECTS</th>
                      </tr>
                    </thead>

                    <tbody>{leaderboardRows}</tbody>
                  </table>
                </Table>
              </Project>
            </Highlight>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withStyles(style)(Campaigns);
