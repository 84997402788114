import React from "react";
import {
  Col,
  Highlight,
  Frame,
  Image,
  List,
  Project,
  Row,
  Loading,
  Table,
  Words,
  withStyles,
} from "arwes";
import { findIndex } from "lodash";
import { darken } from "polished";

import SocketProvider from "../providers/socket";
import KillViewer from "../components/killViewer";
import PieChart from "../components/pieChart";
import ShipIdentifier from "../util/shipIdentifier";

const { identifyShip } = ShipIdentifier;

const style = (theme) => ({
  root: {},
  avatar: {
    maxWidth: "200px",
    height: "auto",
  },
  leaders: {
    marginTop: "1rem",
    userSelect: "none",
    display: "flex",
  },
  commander: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
    width: 0,
  },
  commanderInternal: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  project: {
    marginTop: "1rem",
    userSelect: "none",
  },
  words: {
    marginBottom: "1rem",
    fontSize: "1.1rem",
  },
  glow: {
    transition: "250ms filter ease",
    "&:hover": {
      filter: "brightness(120%)",
    },
  },
  select: {
    backgroundColor: "black",
    color: "#FF6600",
    borderColor: "rgba(153,61,0,0.65)",
    textTransform: "uppercase",
    padding: "12px 18px",
    mozAppearance: "none",
    webkitAppearance: "none",
    appearance: "none",
    position: "absolute",
    top: "15px",
    right: "12px",
    fontFamily: "Eurostile Regular",
    fontSize: "18px",
    fontWeight: "600",
    outline: "none",
    transition: "175ms borderColor ease",
    "&:hover": {
      borderColor: "#FF6600",
    },
  },
  option: {
    "&:checked)": {
      backgroundColor: "#FF6600",
    },
  },
});

class Killboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
    };
  }

  getKillsForCommander(name) {
    const { kills } = this.state.data;

    if (!name.length) {
      return kills;
    }

    return kills.filter((kill) => {
      const direct = kill.attacker
        ? kill.attacker.name === name
        : kill.victim.name === name;
      const associated = kill.associates.find(
        (commander) => commander.name === name
      );

      return direct || associated;
    });
  }

  updateCalc(data) {
    const breakdown = [];
    let breakdownCount = 1;

    for (let kill of data.kills) {
      if (kill.scan && kill.scan.data.shipRaw) {
        let shipTypeIndex = findIndex(breakdown, (type) => {
          return type.raw === kill.scan.data.shipRaw;
        });

        if (shipTypeIndex !== -1) {
          breakdown[shipTypeIndex].value++;
        } else {
          breakdown.push({
            id: breakdownCount,
            label: identifyShip(kill.scan.data.shipRaw),
            raw: kill.scan.data.shipRaw,
            value: 1,
          });

          breakdownCount++;
        }
      }
    }

    breakdown.sort((a, b) => b.value - a.value);

    for (let item of breakdown) {
      item.color = darken(0 + item.id * 0.025, "#FF6600");
    }

    // Efficiency
    let killCount = 0;
    let lossCount = 0;

    data.kills.map((kill) => {
      if (kill.victim) {
        lossCount++;
      } else {
        killCount++;
      }
    });

    const efficiency = [
      { id: 1, label: "Kills", color: "#ff6600", value: killCount },
      { id: 2, label: "Losses", color: "#5c2500", value: lossCount },
    ];

    this.setState({
      data,
      focus: null,
      breakdown,
      efficiency,
    });
  }

  setFocus(kill) {
    if (this.state.focus === kill.id) {
      return this.setState({ ...this.state, focus: null });
    }

    this.setState({ ...this.state, focus: kill.id });
  }

  onCommanderSelect(event) {
    event.persist();

    const {
      target: { value },
    } = event;

    this.setState({
      ...this.state,
      selectedCommander: value,
    });
  }

  onMessage(records) {
    let record = records.length ? records[0] : records;

    if (record && (record.victim || record.attacker)) {
      // Killed by station
      if (
        record.data.opponentName &&
        record.data.opponentName.startsWith("$")
      ) {
        return;
      }

      // Killed by NPC
      if (
        record.victim &&
        !record.data.opponentName.toLowerCase().startsWith("cmdr")
      ) {
        return;
      }

      const { data } = this.state;

      data.kills.push(record);
      data.kills.sort((a, b) => {
        return (
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
      });

      this.updateCalc(data);
    }
  }

  async componentDidMount() {
    const data = await SocketProvider.get("/killboard?days=1000");
    await SocketProvider.get("/subscribe/processedKills");

    data.kills.sort((a, b) => {
      return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
    });

    this.updateCalc(data);
  }

  async componentDidUpdate() {
    const sound = new window.Howl({
      src: "/sound/typing.mp3",
    });

    sound.play();
  }

  componentWillUnmount() {
    SocketProvider.off("message", this.onMessage);
  }

  render() {
    const rows = [];
    const selectOptionsCommander = [];
    const tableData = this.state.selectedCommander
      ? this.getKillsForCommander(this.state.selectedCommander)
      : this.state.data.kills;

    if (!this.state.data || !tableData) {
      return <Loading />;
    }

    for (let i = 0; i < tableData.length; i++) {
      const kill = tableData[i];

      rows.push(
        <KillViewer
          data={kill}
          open={this.state.focus === kill.id}
          clickFn={this.setFocus.bind(this, kill)}
        />
      );
    }

    const top = [];
    const { stats: commanders } = this.state.data.meta;

    commanders.sort((a, b) => b.kills - a.kills);

    for (let i = 0; i < commanders.length; i++) {
      let commander = commanders[i];

      // We only show the top five for now.
      if (top.length !== 5 && commander.name !== "Tharik Otoli") {
        top.push(
          <Project
            animate
            header={commander.name}
            className={`${this.props.classes.commander} topKiller ${this.props.classes.glow}`}
          >
            <div className={this.props.classes.commanderInternal}>
              <Image
                animate
                resources={`/avatars/${commander.name}.jpg`}
                className={this.props.classes.avatar}
              />
              <small>
                #{i + 1} / {commander.kills} KILLS / {commander.eff * 100}% EFF
              </small>
            </div>
          </Project>
        );
      }

      if (commander.name !== "Tharik Otoli") {
        selectOptionsCommander.push(
          <option className={this.props.classes.option}>
            {commander.name}
          </option>
        );
      }
    }

    const topInterdictions = commanders.sort(
      (a, b) => b.interdictions - a.interdictions
    );
    const topInterdictionsList = [];

    for (let commander of topInterdictions) {
      if (commander.interdictions) {
        topInterdictionsList.push(
          <tr>
            <td>{commander.name.toUpperCase()}</td>
            <td>{commander.interdictions}</td>
          </tr>
        );
      }
    }

    return (
      <Row nested>
        <Col s={12} m={9}>
          <Highlight>
            <div>
              <div className={this.props.classes.leaders}>{top}</div>
            </div>
          </Highlight>

          <Project
            animate
            header="Killboard"
            className={this.props.classes.project}
          >
            <select
              onChange={this.onCommanderSelect.bind(this)}
              className={this.props.classes.select}
            >
              <option value="">All CMDRs</option>
              {selectOptionsCommander}
            </select>

            <Table animate>
              <table>
                <thead>
                  <tr>
                    <th>DATE</th>
                    <th>TYPE</th>
                    <th>SYSTEM</th>
                    <th>SHIP</th>
                    <th>VICTIM</th>
                    <th>ATTACKER</th>
                    <th>INTERDICTION</th>
                  </tr>
                </thead>

                <tbody>{rows}</tbody>
              </table>
            </Table>
          </Project>
        </Col>

        <Col s={12} m={3}>
          <Highlight>
            <Project
              animate
              header="Efficiency"
              className={`${this.props.classes.project} ${this.props.classes.glow}`}
            >
              <PieChart data={this.state.efficiency} />
            </Project>
          </Highlight>

          <Highlight>
            <Project
              animate
              header="Victims"
              className={`${this.props.classes.project} ${this.props.classes.glow}`}
            >
              <PieChart data={this.state.breakdown} />
            </Project>
          </Highlight>

          <Highlight>
            <Project
              animate
              header="Interdictions"
              className={`${this.props.classes.project} ${this.props.classes.glow}`}
            >
              <Table animate>
                <thead>
                  <tr>
                    <th style={{ minWidth: "275px" }}>CMDR</th>
                    <th>#</th>
                  </tr>
                </thead>

                <tbody>{topInterdictionsList}</tbody>
              </Table>
            </Project>
          </Highlight>

          <Highlight>
            <Project
              animate
              header="Info"
              className={`${this.props.classes.project} ${this.props.classes.glow}`}
            >
              <Words animate className={this.props.classes.words}>
                Kill reports confirmed by the Straylight Flight Recorder are
                matched with related interdictions and ship scans that happen
                within an allotted time slot.
              </Words>

              <Words animate className={this.props.classes.words}>
                Killboard data from your flight recorder is sent in real-time,
                but a manual data slate reload is currently required to see
                latest kills.
              </Words>
            </Project>
          </Highlight>
        </Col>
      </Row>
    );
  }
}

export default withStyles(style)(Killboard);
