import React from "react";
import { darken } from "polished";
import {
  Button,
  Col,
  Highlight,
  Loading,
  Project,
  Row,
  Table,
  Words,
  withStyles,
} from "arwes";
import { find, findIndex } from "lodash";
import moment from "moment";

import SocketProvider from "../providers/socket";
import BarChart from "../components/barChart";
import LineChart from "../components/lineChart";
import StatDisplay from "../components/statDisplay";

const style = (theme) => ({
  row: {
    "&:hover": {
      background: "#FF6600",
      color: "#000000",
    },
  },
  project: {
    marginTop: "1rem",
    userSelect: "none",
  },
  stats: {
    display: "flex",
    justifyContent: "space-around",
  },
  words: {
    marginBottom: "1rem",
    fontSize: "1.1rem",
  },
  toggle: {
    position: "absolute",
    top: "19.5px",
    right: "19.5px",
  },
  glow: {
    "&:hover": {
      filter: "brightness(120%)",
    },
  },
  select: {
    backgroundColor: "black",
    color: "#FF6600",
    borderColor: "rgba(153,61,0,0.65)",
    textTransform: "uppercase",
    padding: "12px 18px",
    mozAppearance: "none",
    webkitAppearance: "none",
    appearance: "none",
    position: "absolute",
    top: "15px",
    right: "12px",
    fontFamily: "Eurostile Regular",
    fontSize: "18px",
    fontWeight: "600",
    outline: "none",
    transition: "175ms borderColor ease",
    "&:hover": {
      borderColor: "#FF6600",
    },
  },
  option: {
    "&:checked)": {
      backgroundColor: "#FF6600",
    },
  },
});

let pilotCount = 0;

const _resetColor = () => {
  pilotCount = 0;
};

const _colorFn = () => {
  const value = pilotCount;
  const color = darken(value, "#FF6600");

  pilotCount = pilotCount + 0.08;

  return color;
};

const _formatLineData = (history, isInitial) => {
  const systems = [];

  _resetColor();

  for (let item of history) {
    let system = find(systems, { id: item.system });
    const time = moment(item.updated_at)
      .utc()
      .startOf("day")
      .add(14, "hours")
      .toDate()
      .getTime();

    if (!system) {
      system = {
        id: item.system,
        color: _colorFn(),
        data: [
          {
            x: time,
            y: isInitial ? 0 : (item.influence * 100).toFixed(2),
          },
        ],
      };

      systems.push(system);

      pilotCount = pilotCount + 2;
    } else {
      let day = system.data.find((d) => d.x === time);

      if (!day) {
        system.data.push({
          x: time,
          y: isInitial ? 0 : (item.influence * 100).toFixed(2),
        });
      }
    }
  }

  return systems;
};

const _buildStats = (missions, yesterday) => {
  const stats = {
    influence: 0,
    total: missions.length,
    yesterday: 0,
    commanders: 0,
  };

  const commanders = [];

  missions.map((mission) => {
    const straylightEffect = mission.data.minorfactionEffects.find(
      (effect) => effect.minorfactionName === "Straylight Systems"
    );

    stats.influence =
      stats.influence +
      (straylightEffect.influenceGain
        ? straylightEffect.influenceGain.length
        : 0);

    const commanderId = mission.commander.id
      ? mission.commander.id
      : mission.commander;
    const recordedCommander = commanders.indexOf(commanderId);

    if (recordedCommander === -1) {
      commanders.push(commanderId);
    }
  });

  commanders.map(() => {
    stats.commanders++;
  });

  yesterday.map((mission) => {
    const straylightEffect = mission.data.minorfactionEffects.find(
      (effect) => effect.minorfactionName === "Straylight Systems"
    );

    stats.yesterday =
      stats.yesterday +
      (straylightEffect.influenceGain
        ? straylightEffect.influenceGain.length
        : 0);
  });

  const trend = stats.influence - stats.yesterday;

  stats.trend = `${Math.sign(trend) === -1 ? "" : "+"}${trend}`;

  return stats;
};
class Leaderboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      showAllTime: false,
    };
  }

  onMessage(records) {
    let record = records.length ? records[0] : records;

    if (record && record.missionId) {
      // Let's be sure this is a mission
      const { current, data } = this.state;
      const mission = record;

      if (
        mission.isCompleted &&
        mission.data.minorfactionNameOrigin === "Straylight Systems"
      ) {
        current.push(mission);

        // Rebuild stats
        const yesterday =
          this.state.faction.history[this.state.faction.history.length - 1]
            .missions;
        const stats = _buildStats(current, yesterday);

        // Update leaderboard
        const straylightEffect = mission.data.minorfactionEffects.find(
          (effect) => effect.minorfactionName === "Straylight Systems"
        );

        let commanderIndex = findIndex(data, { name: mission.commander.name });

        if (commanderIndex !== -1) {
          data[commanderIndex].meta.score =
            data[commanderIndex].meta.score +
            (straylightEffect.influenceGain
              ? straylightEffect.influenceGain.length
              : 0);
          data[commanderIndex].meta.total++;
        } else {
          const commander = {
            ...mission.commander,
            meta: {
              score: straylightEffect.influenceGain
                ? straylightEffect.influenceGain.length
                : 0,
              total: 1,
            },
          };

          data.push(commander);
        }

        data.sort((a, b) => {
          return b.meta.score - a.meta.score;
        });

        // Set state
        this.setState({
          ...this.state,
          current,
          stats,
          data,
        });
      }
    }
  }

  async onSetDataDays(event) {
    event.persist();

    const {
      target: { value },
    } = event;

    this.setState({
      ...this.state,
      isLoadingLeaderboard: true,
    });

    const data = await SocketProvider.get(`/leaderboard?days=${value}`);

    data.sort((a, b) => {
      return b.meta.score - a.meta.score;
    });

    this.setState({
      ...this.state,
      data,
      dataDays: value,
      isLoadingLeaderboard: false,
    });
  }

  async componentDidMount() {
    const data = await SocketProvider.get("/leaderboard?days=7");
    const { straylight: faction, current } = await SocketProvider.get(
      "/faction"
    );
    await SocketProvider.get("/subscribe/missions");

    data.sort((a, b) => {
      return b.meta.score - a.meta.score;
    });

    // Animate the delta from 0 to current values
    const factionInitial = { history: [] };
    const graphKeys = [];

    for (let commander of faction.commanders) {
      // Build graph keys
      if (!graphKeys.find((name) => name === commander.name)) {
        graphKeys.push(commander.name);
      }

      // Assign CMDR colors
      commander.color = _colorFn(commander.name);
    }

    _resetColor();

    // Enrich faction history with CMDR data
    faction.history.map((item) => {
      const { updated_at } = item;

      factionInitial.history.push({
        updated_at,
        influence: 0,
      });

      const stats = {};

      item.missions.map((mission) => {
        const { name } = mission.commander;
        const straylightEffect = mission.data.minorfactionEffects.find(
          (effect) => effect.minorfactionName === "Straylight Systems"
        );

        if (stats[name]) {
          stats[name] =
            stats[name] +
            (straylightEffect.influenceGain
              ? straylightEffect.influenceGain.length
              : 0);
        } else {
          stats[name] = straylightEffect.influenceGain
            ? straylightEffect.influenceGain.length
            : 0;
        }
      });

      for (let commander of faction.commanders) {
        // Seed this history item with all commander names and colors
        if (!item.hasOwnProperty(commander.name)) {
          item[commander.name] = 0;
          item[`${commander.name}Color`] = commander.color;
        }
      }

      const commandersArr = Object.keys(stats);

      commandersArr.sort();

      for (let commander of commandersArr) {
        item[commander] = stats[commander];
      }

      return item;
    });

    // Build influence data for line graph
    const influence = _formatLineData(faction.history);
    const influenceInitial = _formatLineData(faction.history, true);

    // Build pending stats
    const yesterday = faction.history[faction.history.length - 1].missions;
    const stats = _buildStats(current, yesterday);

    this.setState({
      data,
      faction: factionInitial,
      isLoading: true,
      graphKeys,
      influence: influenceInitial,
      barToggle: true,
      current,
      stats,
    });

    setTimeout(() => this.setState({ ...this.state, faction }), 500);
    setTimeout(
      () => this.setState({ ...this.state, influence, isLoading: false }),
      900
    );

    SocketProvider.on("message", this.onMessage.bind(this));
  }

  async componentDidUpdate() {
    const sound = new window.Howl({
      src: "/sound/typing.mp3",
    });

    sound.play();
  }

  componentWillUnmount() {
    SocketProvider.off("message", this.onMessage);
  }

  render() {
    if (!this.state.data || !this.state.faction) {
      return <Loading />;
    }

    const rows = [];
    const selectableDataDays = [
      <option className={this.props.classes.option} value="7">
        Past Week
      </option>,
      <option className={this.props.classes.option} value="30">
        Past Month
      </option>,
      <option className={this.props.classes.option} value="365">
        All Time
      </option>,
    ];

    for (let i = 0; i < this.state.data.length; i++) {
      let row = this.state.data[i];

      rows.push(
        <tr className={this.props.classes.row}>
          <td>{i + 1}</td>
          <td>{row.name.toUpperCase()}</td>
          <td>{row.meta.score}</td>
          <td>{row.meta.total}</td>
        </tr>
      );
    }

    const leaderboard = this.state.isLoadingLeaderboard ? (
      <Loading />
    ) : (
      <Table animate>
        <table>
          <thead>
            <tr>
              <th>RANK</th>
              <th>CMDR</th>
              <th>INFLUENCE</th>
              <th>MISSIONS</th>
            </tr>
          </thead>

          <tbody>{rows}</tbody>
        </table>
      </Table>
    );

    return (
      <Row nested>
        <Col m={12} l={6}>
          <Highlight>
            <Project
              animate
              header="INF / Current"
              className={`${this.props.classes.project} ${this.props.classes.glow}`}
            >
              <section className={this.props.classes.stats}>
                <StatDisplay
                  label="commanders"
                  value={this.state.stats.commanders}
                />
                <StatDisplay label="missions" value={this.state.stats.total} />
                <StatDisplay
                  label="influence"
                  value={this.state.stats.influence}
                />
                <StatDisplay label="trend" value={this.state.stats.trend} />
              </section>
            </Project>
          </Highlight>

          <Highlight>
            <Project
              animate
              header="INF / Missions"
              className={`${this.props.classes.project} ${this.props.classes.glow}`}
            >
              <Button
                id="barToggle"
                className={this.props.classes.toggle}
                onClick={() =>
                  this.setState({
                    ...this.state,
                    barToggle: !this.state.barToggle,
                  })
                }
              >
                Toggle view
              </Button>

              <BarChart
                data={this.state.faction.history}
                keys={this.state.graphKeys}
                toggle={this.state.barToggle}
                legend="INFLUENCE"
              />
            </Project>
          </Highlight>

          <Highlight>
            <Project
              animate
              header="Info"
              className={`${this.props.classes.project} ${this.props.classes.glow}`}
            >
              <Words animate className={this.props.classes.words}>
                Graph statistics reflect the last 7 days. The amount of INF for
                each mission turned in is calculated to create a cumulative
                score for each commander. "Current" denotes pending influence
                achieved since the previous tick. Note that influence cannot be
                gained during a war.
              </Words>

              <Words animate className={this.props.classes.words}>
                Only missions for Straylight Systems count towards these
                statistics.
              </Words>

              <Words animate className={this.props.classes.words}>
                Since v1.2.0, your flight recorder updates mission data in
                real-time.
              </Words>
            </Project>
          </Highlight>
        </Col>

        <Col m={12} l={6}>
          <Highlight>
            <Project
              animate
              header="INF / Systems"
              className={`${this.props.classes.project} ${this.props.classes.glow}`}
            >
              <LineChart data={this.state.influence} />
            </Project>
          </Highlight>
          <Highlight>
            <Project
              animate
              header="INF / Leaderboard"
              className={this.props.classes.project}
            >
              <select
                onChange={this.onSetDataDays.bind(this)}
                className={this.props.classes.select}
              >
                {selectableDataDays}
              </select>

              {leaderboard}
            </Project>
          </Highlight>
        </Col>
      </Row>
    );
  }
}

export default withStyles(style)(Leaderboard);
