class SessionProvider {
  data = null;

  set(data) {
    this.data = data;
  }

  get() {
    return this.data;
  }
}

const sessionProvider = new SessionProvider();

export default sessionProvider;
