import React from "react";
import { Heading, Frame, Line, Row, Col, List, Words, withStyles } from "arwes";
import moment from "moment";

import ShipViewer from "./shipViewer";
import ShipIdentifier from "../util/shipIdentifier";

const { identifyShip } = ShipIdentifier;

const style = (theme) => ({
  base: {
    cursor: "pointer",
    "&:hover": {
      background: "#FF6600",
      color: "#000000",
    },
  },
  loss: {
    cursor: "pointer",
    color: "red",
    "&:hover": {
      background: "red",
      color: "#000000",
    },
  },
  expanded: {
    "&:hover": {
      background: "#000000",
      color: "#FF6600",
    },
  },
  expandedLoss: {
    borderColor: "red !important",
    "&:hover": {
      background: "#000000",
      color: "red",
    },
  },
  report: {
    padding: "10px",
    minHeight: "237px",
  },
  reportHeading: {
    margin: "3px 0 10px 3px",
  },
  reportCol: {
    display: "flex",
    alignItems: "start",
    minHeight: "200px",
    padding: "20px",
  },
  reportCol2: {
    display: "flex",
    alignItems: "start",
    minHeight: "200px",
    padding: "20px",
  },
  reportShipParent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "200px",
  },
  reportShip: {
    height: "200px",
    overflow: "hidden",
  },
  reportListItem: {
    marginBottom: "5px",
  },
});

const killViewer = withStyles(style)(({ classes, data, open, clickFn }) => {
  let associates = [];
  let associatesCount = 0;
  let associateSfx = "";

  if (data.victim && data.data.wingOpponentNames) {
    for (let i = 1; i < data.data.wingOpponentNames.length; i++) {
      associates.push(
        <li className={classes.reportListItem}>
          <strong>ASSIST:</strong>{" "}
          {data.data.wingOpponentNames[i].toUpperCase()}
        </li>
      );

      associatesCount++;
    }
  } else {
    for (let associate of data.associates) {
      associates.push(
        <li className={classes.reportListItem}>
          <strong>ASSIST:</strong> CMDR {associate.name.toUpperCase()}
        </li>
      );

      associatesCount++;
    }
  }

  if (associatesCount) {
    associateSfx = ` + ${associatesCount}`;
  }

  let scanTitle;
  let scanShipRaw = data.scan ? data.scan.data.shipRaw : null;
  let scanShip = scanShipRaw ? identifyShip(scanShipRaw) : "N/A";
  let scanPower = "No scan";
  let scanSquadron = "No scan";
  let scanRank = "No scan";
  let scanTarget = data.victim
    ? data.victim.name.toUpperCase()
    : data.data.opponentName && data.data.opponentName.toUpperCase();

  if (data.scan) {
    scanPower = data.scan.data.power ? data.scan.data.power : "None";
    scanSquadron = data.scan.data.squadronId
      ? data.scan.data.squadronId
      : "None";
    scanRank = data.scan.data.rank ? data.scan.data.rank : "None";
    scanTitle = `${data.data.opponentName} / ${
      data.scan.data.ship ? data.scan.data.ship : data.scan.data.shipRaw
    }`;
  } else {
    scanTitle = data.data.opponentName;
  }

  // Loss
  if (data.victim) {
    scanSquadron = "SLGT";
    scanShipRaw = data.ship.shipType;
    scanShip = identifyShip(scanShipRaw);
    scanTitle = `${data.victim.name} / ${scanShip}`;
  }

  const closedView = (
    <tr
      className={`${data.victim ? classes.loss : classes.base}`}
      onClick={clickFn}
    >
      <td>
        {moment(data.timestamp).utc().add(1286, "years").format("YYYY")}-
        {moment(data.timestamp).utc().format("MM-DD HH:mm:ss")}
      </td>
      <td>{data.victim ? "LOSS" : "KILL"}</td>
      <td>{data.data.starsystemName}</td>
      <td>{scanShip ? scanShip.toUpperCase() : "N/A"}</td>
      <td>
        {data.victim
          ? data.victim.name.toUpperCase()
          : data.data.opponentName.toUpperCase()}
      </td>
      <td>
        {data.attacker
          ? `${data.attacker.name.toUpperCase()}${associateSfx}`
          : data.data.opponentName
          ? data.data.opponentName.toUpperCase()
          : `${data.data.wingOpponentNames[0]
              .split("Cmdr ")[1]
              .toUpperCase()} + ${data.data.wingOpponentNames.length - 1}`}
      </td>
      <td>
        {data.interdiction
          ? data.interdiction.commander.name.toUpperCase()
          : "NONE"}
      </td>
    </tr>
  );

  const expandedView = (
    <tr
      className={`${data.victim ? classes.loss : classes.base} ${
        data.victim ? classes.expandedLoss : classes.expanded
      } ${data.victim ? "shipLoss" : ""}`}
      onClick={clickFn}
    >
      <td colspan={7}>
        <Frame animate level={0} corners={0} className={classes.report}>
          <Heading animate node="h3" className={classes.reportHeading}>
            {scanTitle}
          </Heading>

          <Line animate />

          <Row nested className={classes.reportRow}>
            {scanShipRaw && (
              <Col m={12} l={4} className={classes.reportShipParent}>
                <ShipViewer
                  ship={scanShipRaw}
                  className={classes.reportShip}
                  isLoss={!!data.victim}
                />
              </Col>
            )}

            <Col m={12} l={4} className={classes.reportCol}>
              <List node="ul">
                <li className={classes.reportListItem}>
                  <strong>TARGET:</strong> CMDR {scanTarget}
                </li>
                <li className={classes.reportListItem}>
                  <strong>SHIP CLASS:</strong> {scanShip ? scanShip : "N/A"}
                </li>
                <li className={classes.reportListItem}>
                  <strong>LOCATION:</strong> {data.data.starsystemName}
                </li>
                {data.attacker && (
                  <li className={classes.reportListItem}>
                    <strong>POWER:</strong> {scanPower}
                  </li>
                )}
                <li className={classes.reportListItem}>
                  <strong>SQUADRON:</strong> {scanSquadron}
                </li>
                {data.attacker && (
                  <li className={classes.reportListItem}>
                    <strong>RANK:</strong> {scanRank}
                  </li>
                )}
              </List>
            </Col>

            <Col
              m={12}
              l={4}
              className={classes.reportCol2}
              style={{ paddingLeft: "20px" }}
            >
              <List node="ul">
                <li className={classes.reportListItem}>
                  <strong>FINAL BLOW:</strong>{" "}
                  {data.attacker
                    ? `CMDR ${data.attacker.name.toUpperCase()}`
                    : data.data.opponentName
                    ? data.data.opponentName.toUpperCase()
                    : data.data.wingOpponentNames[0].toUpperCase()}
                </li>
                <li className={classes.reportListItem}>
                  <strong>INTERDICTION: </strong>{" "}
                  {data.interdiction
                    ? `CMDR ${data.interdiction.commander.name.toUpperCase()}`
                    : "None"}
                </li>
                {associates}
              </List>
            </Col>
          </Row>
        </Frame>
      </td>
    </tr>
  );

  return open ? expandedView : closedView;
});

export default killViewer;
