import React from "react";
import {
  Highlight,
  Loading,
  Project,
  Table,
  Row,
  Col,
  withStyles,
} from "arwes";
import moment from "moment";
import { findIndex } from "lodash";
import StatDisplay from "../components/statDisplay";

import SocketProvider from "../providers/socket";

const style = (theme) => ({
  row: {
    "&:hover": {
      background: "#FF6600",
      color: "#000000",
    },
  },
  project: {
    marginTop: "1rem",
    userSelect: "none",
  },
  stats: {
    display: "flex",
    justifyContent: "space-around",
  },
  words: {
    marginBottom: "1rem",
  },
});

class MissionLog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      weekStats: {
        missions: [],
        factions: [],
      },
      dayStats: {
        missions: [],
        factions: [],
      },
    };
  }

  calculateWeekStats(missions) {
    const { weekStats } = this.state;
    const oneWeekInThePast = moment().subtract(7, "days").toDate();

    for (const mission of missions) {
      if (new Date(mission.updatedAt).getTime() >= oneWeekInThePast) {
        weekStats.missions.push(mission);
      }
    }

    // faction: { name, score, total }
    for (const mission of weekStats.missions) {
      if (mission.isCompleted) {
        const existingFaction = findIndex(weekStats.factions, {
          name: mission.data.minorfactionNameOrigin,
        });
        let factionEffect = mission.data.influenceGain;

        if (mission.data.minorfactionEffects) {
          const effect = mission.data.minorfactionEffects.find(
            (effect) =>
              effect.minorfactionName === mission.data.minorfactionNameOrigin
          );

          if (effect?.influenceGain) {
            factionEffect = effect.influenceGain;
          }
        }

        if (existingFaction !== -1) {
          weekStats.factions[existingFaction] = {
            ...weekStats.factions[existingFaction],
            score:
              weekStats.factions[existingFaction].score + factionEffect.length,
            total: weekStats.factions[existingFaction].total + 1,
          };
        } else {
          weekStats.factions.push({
            name: mission.data.minorfactionNameOrigin,
            score: factionEffect.length,
            total: 1,
          });
        }
      }
    }

    weekStats.factions.sort((a, b) => {
      return b.score - a.score;
    });

    return weekStats;
  }

  calculateDayStats(missions) {
    const { dayStats } = this.state;
    const oneDayInThePast = moment().subtract(1, "days").toDate();

    for (const mission of missions) {
      if (new Date(mission.updatedAt).getTime() >= oneDayInThePast) {
        dayStats.missions.push(mission);
      }
    }

    // faction: { name, score, total }
    for (const mission of dayStats.missions) {
      if (mission.isCompleted) {
        const existingFaction = findIndex(dayStats.factions, {
          name: mission.data.minorfactionNameOrigin,
        });
        let factionEffect = mission.data.influenceGain;

        if (mission.data.minorfactionEffects) {
          const effect = mission.data.minorfactionEffects.find(
            (effect) =>
              effect.minorfactionName === mission.data.minorfactionNameOrigin
          );

          if (effect?.influenceGain) {
            factionEffect = effect.influenceGain;
          }
        }

        if (existingFaction !== -1) {
          dayStats.factions[existingFaction] = {
            ...dayStats.factions[existingFaction],
            score:
              dayStats.factions[existingFaction].score + factionEffect.length,
            total: dayStats.factions[existingFaction].total + 1,
          };
        } else {
          dayStats.factions.push({
            name: mission.data.minorfactionNameOrigin,
            score: factionEffect.length,
            total: 1,
          });
        }
      }
    }

    dayStats.factions.sort((a, b) => {
      return b.score - a.score;
    });

    return dayStats;
  }

  onMessage(records) {
    let record = records.length ? records[0] : records;

    if (record && record.missionId) {
      // Let's be sure this is a mission
      const { data, dayStats, weekStats } = this.state;
      const mission = record;
      const existingMission = findIndex(data, { id: mission.id });
      const existingDayStatsMission = findIndex(dayStats.missions, {
        id: mission.id,
      });
      const existingWeekStatsMission = findIndex(weekStats.missions, {
        id: mission.id,
      });

      if (existingMission !== -1) {
        data[existingMission] = mission;
      } else {
        data.push(mission);
      }

      data.sort((a, b) => {
        return (
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
      });

      if (existingDayStatsMission !== -1) {
        dayStats.missions[existingDayStatsMission] = mission;
      } else {
        dayStats.missions.push(mission);
      }

      if (existingWeekStatsMission !== -1) {
        weekStats.missions[existingWeekStatsMission] = mission;
      } else {
        weekStats.missions.push(mission);
      }

      this.setState({
        ...this.state,
        data,
        dayStats,
        weekStats,
      });
    }
  }

  async componentDidMount() {
    const data = await SocketProvider.get(
      "/mission?sort=updatedAt DESC&limit=500"
    );
    await SocketProvider.get("/subscribe/missions");

    data.sort((a, b) => {
      return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
    });

    const dayStats = this.calculateDayStats(data);
    const weekStats = this.calculateWeekStats(data);

    this.setState({
      ...this.state,
      data: [...this.state.data, ...data],
      dayStats,
      weekStats,
    });

    SocketProvider.on("message", this.onMessage.bind(this));
  }

  async componentDidUpdate() {
    const sound = new window.Howl({
      src: "/sound/typing.mp3",
    });

    sound.play();
  }

  componentWillUnmount() {
    SocketProvider.off("message", this.onMessage);
  }

  render() {
    if (!this.state.data.length) {
      return <Loading />;
    }

    const rows = [];

    for (let row of this.state.data) {
      const { isAbandoned, isFailed, isCompleted } = row;
      let currentState = "OPEN";

      if (isAbandoned) {
        currentState = "ABANDONED";
      } else if (isFailed) {
        currentState = "FAILED";
      } else if (isCompleted) {
        currentState = "COMPLETED";
      }

      let factionEffect;

      if (row.data.minorfactionEffects) {
        const effect = row.data.minorfactionEffects.find(
          (effect) =>
            effect.minorfactionName === row.data.minorfactionNameOrigin
        );

        if (effect?.influenceGain) {
          factionEffect = effect.influenceGain;
        }
      }

      rows.push(
        <tr className={this.props.classes.row}>
          <td>
            {moment(row.updatedAt).utc().add(1286, "years").format("YYYY")}-
            {moment(row.updatedAt).utc().format("MM-DD HH:mm:ss")}
          </td>
          <td>{row.commander.name.toUpperCase()}</td>
          <td>{row.data.starsystemNameOrigin}</td>
          <td>{row.data.minorfactionNameOrigin}</td>
          <td>{row.data.missionName.split("Mission_")[1]}</td>
          <td>{currentState}</td>
          <td>{factionEffect ? factionEffect : row.data.influenceGain}</td>
        </tr>
      );
    }

    const { weekStats, dayStats } = this.state;

    return (
      <div>
        <Row nested noMargin>
          <Col m={12} l={6}>
            <Highlight>
              <Project
                animate
                header="This week"
                className={`${this.props.classes.project} ${this.props.classes.glow}`}
              >
                <section className={this.props.classes.stats}>
                  <StatDisplay
                    label="total missions"
                    value={weekStats.missions.length}
                  />
                  {weekStats.factions[0] && (
                    <StatDisplay
                      label={weekStats.factions[0].name}
                      value={weekStats.factions[0].total}
                    />
                  )}
                  {weekStats.factions[1] && (
                    <StatDisplay
                      label={weekStats.factions[1].name}
                      value={weekStats.factions[1].total}
                    />
                  )}
                  {/* <StatDisplay label={this.state.stats.factions[1].name} value={formatNumber(sumBy(this.state.ranks, 'meta.score'))} /> */}
                </section>
              </Project>
            </Highlight>
          </Col>

          <Col m={12} l={6}>
            <Highlight>
              <Project
                animate
                header="Past day"
                className={`${this.props.classes.project} ${this.props.classes.glow}`}
              >
                <section className={this.props.classes.stats}>
                  <StatDisplay
                    label="total missions"
                    value={dayStats.missions.length}
                  />
                  {dayStats.factions[0] && (
                    <StatDisplay
                      label={dayStats.factions[0].name}
                      value={dayStats.factions[0].total}
                    />
                  )}
                  {dayStats.factions[1] && (
                    <StatDisplay
                      label={dayStats.factions[1].name}
                      value={dayStats.factions[1].total}
                    />
                  )}
                  {/* <StatDisplay label={this.state.stats.factions[1].name} value={formatNumber(sumBy(this.state.ranks, 'meta.score'))} /> */}
                </section>
              </Project>
            </Highlight>
          </Col>
        </Row>

        <Highlight>
          <Project
            animate
            header="Mission Logs"
            className={this.props.classes.project}
          >
            <Table animate>
              <table>
                <thead>
                  <tr>
                    <th>TIMESTAMP</th>
                    <th>CMDR</th>
                    <th>ORIGIN</th>
                    <th>FACTION</th>
                    <th>TYPE</th>
                    <th>STATE</th>
                    <th>INF</th>
                  </tr>
                </thead>

                <tbody>{rows}</tbody>
              </table>
            </Table>
          </Project>
        </Highlight>
      </div>
    );
  }
}

export default withStyles(style)(MissionLog);
